import { useEffect, useMemo, useRef, useState } from "react";
import { useFormik } from "formik";

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";

import { EntryRow, GoalSectionHolder, TotalCalculationRow } from "components";
import { useInput } from "contexts";

import { useIsMobile } from "hooks";
import { Typography } from "@mui/material";
import { createValueString } from "utils";

export const LHI_FIELDS = [
  { id: "exercise", label: "Exercise" },
  { id: "fitnessRating", label: "Fitness Rating" },
  { id: "foodDiet", label: "Food / Diet" },
  { id: "morningRoutineRitual", label: "Morning Routine / Ritual" },
  { id: "meditationGratitudePrayer", label: "Meditation / Gratitude / Prayer" },
  { id: "loveForWork", label: "Love for Work" },
  { id: "authenticity", label: "Authenticity" },
  { id: "futurePlanning", label: "Future Planning" },
  { id: "romance", label: "Romance" },
  { id: "children", label: "Children" },
  { id: "parentsSiblings", label: "Parents, Siblings, etc." },
  { id: "friends", label: "Friends" },
  { id: "prosperityAtWork", label: "Prosperity at Work" },
  { id: "hobbies", label: "Hobbies" },
  { id: "adventureTravel", label: "Adventure, Travel" },
  { id: "excitementRiskVariety", label: "Excitement, Risk, Variety" },
  { id: "timeContribution", label: "Time Contribution" },
  { id: "moneyContribution", label: "Money Contribution" },
];

const initialValues = {
  exercise: 0,
  fitnessRating: 0,
  foodDiet: 0,
  morningRoutineRitual: 0,
  meditationGratitudePrayer: 0,
  loveForWork: 0,
  authenticity: 0,
  futurePlanning: 0,
  romance: 0,
  children: 0,
  parentsSiblings: 0,
  friends: 0,
  prosperityAtWork: 0,
  hobbies: 0,
  adventureTravel: 0,
  excitementRiskVariety: 0,
  timeContribution: 0,
  moneyContribution: 0,
};

export const LifeHappinessIndex = () => {
  const { submitSection, goal, getGoalData } = useInput();
  const [error, setError] = useState(false);
  const { isSmallMobile } = useIsMobile();
  const timer = useRef<any>();
  const [initialized, setInitialized] = useState(false);

  const formik = useFormik({
    initialValues,
    onSubmit: (e) => {
      if (!initialized) return;
      if (changed) submitSection("lifeHappinessIndex", e);
      if (timer.current) {
        clearTimeout(timer.current);
      }
    },
  });

  const changed = useMemo(() => {
    const order = LHI_FIELDS.map(({ id }) => id);
    if (!initialized) return false;
    try {
      return (
        createValueString(order, formik.values) !==
        createValueString(order, goal && goal.lifeHappinessIndex)
      );
    } catch {
      return true;
    }
  }, [formik.values, goal && goal.lifeHappinessIndex]);

  useEffect(() => {
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      if (changed) {
        formik.submitForm();
      }
    }, 5000);
  }, [formik.values, changed]);

  useEffect(() => {
    setError(false);
    try {
      formik.setValues(goal.lifeHappinessIndex || {});
      setInitialized(true);
    } catch (err) {
      setError(true);
    }
  }, [goal && goal.lifeHappinessIndex]);

  // save on exit
  useEffect(
    () => () => {
      formik.submitForm();
    },
    []
  );

  const { averageLifeHappinessIndex } = useMemo(() => {
    let averageLifeHappinessIndex = 0;

    try {
      if (goal._averages && goal._averages.lifeHappinessIndex) {
        const values = Object.values(goal._averages.lifeHappinessIndex);
        const length = values.length;
        const sum = values.reduce(
          (prev: number, curr) => prev + Number(curr) || 0,
          0
        );
        averageLifeHappinessIndex = sum / length;
      }
    } catch {}
    return {
      averageLifeHappinessIndex: Number(averageLifeHappinessIndex.toFixed(2)),
    };
  }, [goal && goal._averages, formik.values]);

  const { currentLifeHappinessIndex } = useMemo(() => {
    let currentLifeHappinessIndex = 0;
    try {
      const values = Object.values(formik.values);
      const length = values.length;
      const sum = values.reduce((prev, curr) => prev + Number(curr) || 0, 0);
      currentLifeHappinessIndex = sum / length;
    } catch {}
    return {
      currentLifeHappinessIndex: Number(currentLifeHappinessIndex.toFixed(2)),
    };
  }, [formik.values]);

  const { lastLifeHappinessIndex } = useMemo(() => {
    let lastLifeHappinessIndex = 0;
    try {
      const values = Object.values(goal._previous.lifeHappinessIndex);
      const length = values.length;
      const sum = values.reduce(
        (prev: number, curr) => prev + Number(curr) || 0,
        0
      );
      lastLifeHappinessIndex = sum / length;
    } catch {}
    return {
      lastLifeHappinessIndex: Number(lastLifeHappinessIndex).toFixed(2),
    };
  }, [goal && goal._previous]);

  const totalLifeHappinessIndex = {
    average: averageLifeHappinessIndex,
    current: currentLifeHappinessIndex,
    last: lastLifeHappinessIndex,
  };

  const rows = LHI_FIELDS.map((field) => {
    return (
      <EntryRow
        goal={goal}
        showBox={isSmallMobile}
        key={field.id}
        id={field.id}
        label={field.label}
        section="lifeHappinessIndex"
        formik={formik}
      />
    );
  });

  return (
    <GoalSectionHolder
      onRefresh={getGoalData}
      error={error}
      changed={changed}
      title="lifeHappinessIndex"
      onSave={formik.submitForm}
    >
      <Box paddingBottom={4}>
        <Typography variant="subtitle2">
          How well you are feeling in each category?{" "}
          <b>Rate yourself on a 1-10 scale.</b>
          <br />
          An average of the categories will automatically be taken and put in
          LHI Average.
        </Typography>
      </Box>
      <Box onSubmit={formik.handleSubmit} component={"form"}>
        {isSmallMobile && (
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            {rows}
          </Box>
        )}
        {!isSmallMobile && (
          <Table size="small" padding="normal" sx={{ width: "100%" }}>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Current</TableCell>
                <TableCell>Last Quarter</TableCell>
                <TableCell>Difference</TableCell>
                <TableCell>Average</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows}
              <TotalCalculationRow
                showBox={isSmallMobile}
                label="LHI Average"
                prepend=""
                values={totalLifeHappinessIndex}
              />
            </TableBody>
          </Table>
        )}
        <button type="submit" style={{ display: "none" }}></button>
      </Box>
    </GoalSectionHolder>
  );
};
