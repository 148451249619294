import Box from "@mui/material/Box";
import { useApp } from "contexts";
import { PublicView } from "./PublicView";
import { AdminView } from "./AdminView";

export const LoginPage = () => {
  const { adminLoginMode } = useApp();

  return (
    <Box
      sx={{
        position: "relative",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        background: "black",
        zIndex: 0,
        overflow: "hidden",
      }}
    >
      <img
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          objectFit: "cover",
          zIndex: 500,
          opacity: 0.7,
        }}
        src="/login-bg.jpg"
      />
      <div style={{ zIndex: 1000 }}>
        <Box
          sx={{
            marginTop: 10,
            width: "500px",
            padding: 6,
            borderRadius: 3,
            position: "relative",
            overflow: "hidden",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              filter: "blur(2px)",
              opacity: 0.2,
              width: "100%",
              height: "100%",
              position: "absolute",
              top: 0,
              left: 0,
              background: "white",
              zIndex: -1,
            }}
          ></div>
          <img style={{ width: "50%", minWidth: "250px" }} src="logo.svg" />
          {adminLoginMode ? <AdminView /> : <PublicView />}
        </Box>
      </div>
    </Box>
  );
};
