import { Box, Card, Typography } from "@mui/material";
import { FC } from "react";
import { localizeNumber } from "utils";

export const Overview: FC<{ assets: number; liabilities: number }> = ({
  assets,
  liabilities,
}) => {
  const netWorth = assets - liabilities;

  return (
    <Box pt={2} sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <Card
        variant="outlined"
        sx={{ p: 2, display: "flex", gap: 2, alignItems: "center" }}
      >
        <Typography fontSize={20} minWidth={150}>
          Assets{" "}
        </Typography>
        <Typography color="green" fontSize={30} fontWeight={"bold"}>
          {localizeNumber(assets)}
        </Typography>
      </Card>
      <Card
        variant="outlined"
        sx={{ p: 2, display: "flex", gap: 2, alignItems: "center" }}
      >
        <Typography fontSize={20} minWidth={150}>
          Liabilities{" "}
        </Typography>
        <Typography color="red" fontSize={30} fontWeight={"bold"}>
          {liabilities ? "-" : ""}
          {localizeNumber(liabilities)}
        </Typography>
      </Card>
      <Card
        variant="outlined"
        sx={{ p: 2, display: "flex", gap: 2, alignItems: "center" }}
      >
        <Typography fontSize={20} minWidth={150}>
          Net Worth{" "}
        </Typography>
        <Typography color={"primary"} fontSize={30} fontWeight={"bold"}>
          {localizeNumber(Number(netWorth).toFixed(2))}
        </Typography>
      </Card>
    </Box>
  );
};
