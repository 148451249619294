import {
  AppBar,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { FC, useState } from "react";
import { useApp } from "contexts";
import { UserInfoBox } from "./UserInfoBox";
import { Navigation } from "./Navigation";
import { Menu } from "@mui/icons-material";
import { useIsMobile } from "hooks";
import { useLocation } from "react-router-dom";

export const AppPage: FC<{ children?: any }> = ({ children }) => {
  const { pathname } = useLocation();

  const { logout } = useApp();
  const drawerWidth = 250;
  const { isMobile } = useIsMobile();

  const [showDrawer, setShowDrawer] = useState(false);

  if (pathname == "/login") return <>{children}</>;

  return (
    <Box sx={{ display: "flex", maxHeight: "100vh", overflow: "hidden" }}>
      <AppBar position="fixed" sx={{ zIndex: 1000000 }}>
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {isMobile && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                pr: 2,
              }}
            >
              <IconButton onClick={() => setShowDrawer(!showDrawer)}>
                <Menu />
              </IconButton>
            </Box>
          )}
          <Box>
            <img
              style={{
                height: "40px",
              }}
              src="logo.svg"
            />
          </Box>
          <Box sx={{ pl: 2, flexGrow: 1 }}>
            {!isMobile && (
              <Typography variant="h5" component="div" fontWeight="bold">
                Master Abundance Playbook
              </Typography>
            )}
          </Box>
          <Button color="inherit" variant="contained" onClick={logout}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant={isMobile ? "temporary" : "permanent"}
        open={showDrawer}
        onClose={() => setShowDrawer(false)}
        anchor="left"
      >
        <Toolbar />
        <UserInfoBox />
        <Divider />
        <Navigation />
      </Drawer>
      <Box
        component="main"
        sx={(theme) => ({
          flex: 1,
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          bgcolor: "background.default",
        })}
      >
        <Toolbar />
        <Box
          flex={1}
          m={3}
          height="100%"
          maxHeight="100%"
          display="flex"
          sx={{ overflowY: "auto" }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};
