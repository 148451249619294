import { Button, TextField, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { useApp } from "contexts";
import { useFormik } from "formik";
import * as yup from "yup";

const schema = yup.object({
  email: yup.string().email().required(),
  password: yup.string().required(),
});

export const AdminView = () => {
  const { loginByEmail } = useApp();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: schema,
    onSubmit: async ({ email, password }) => {
      await loginByEmail(email, password);
    },
  });

  return (
    <Box pt={4}>
      <Typography mb={2} textAlign="center" variant="h5" fontWeight="bold">
        Admin Login
      </Typography>
      <Box
        onSubmit={formik.handleSubmit}
        component="form"
        display="flex"
        flexDirection="column"
        gap={2}
      >
        <TextField
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          fullWidth
          size="small"
          autoComplete="off"
          label="Email Address"
          helperText={formik.errors.email}
          error={Boolean(formik.errors.email)}
        />
        <TextField
          type="password"
          name="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          fullWidth
          size="small"
          label="Password"
          helperText={formik.errors.password}
          error={Boolean(formik.errors.password)}
        />
        <Button type="submit" variant="contained">
          Login
        </Button>
      </Box>
    </Box>
  );
};
