import { useEffect, useMemo, useRef, useState } from "react";
import { useFormik } from "formik";
import Box from "@mui/material/Box";
import { GoalSectionHolder } from "components";
import { useInput } from "contexts";

import { Stack, TextField, Typography } from "@mui/material";

const DATA_FORMAT = ["", "", "", "", "", ""];

export const Priorities = () => {
  const { goal, submitSection, getGoalData } = useInput();
  const [error, setError] = useState(false);
  const timer = useRef<any>();
  const [initialized, setInitialized] = useState(false);

  const formik = useFormik({
    initialValues: DATA_FORMAT,
    onSubmit: (e) => {
      if (!initialized) return;
      if (changed) submitSection("priorities", e);
      if (timer.current) {
        clearTimeout(timer.current);
      }
    },
  });

  useEffect(() => {
    setError(false);
    try {
      const data = goal && goal.priorities;
      if (!data) return;
      formik.setValues(data);
      setInitialized(true);
    } catch {
      setError(true);
    }
  }, [goal && goal.priorities]);

  const changed = useMemo(() => {
    if (!initialized) return false;
    try {
      return (
        JSON.stringify(formik.values) !==
        JSON.stringify(goal && goal.priorities)
      );
    } catch {
      return true;
    }
  }, [formik.values, goal && goal.priorities]);

  useEffect(() => {
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      if (changed) {
        formik.submitForm();
      }
    }, 5000);
  }, [formik.values, changed]);

  // save on exit
  useEffect(
    () => () => {
      formik.submitForm();
    },
    []
  );

  return (
    <GoalSectionHolder
      onRefresh={getGoalData}
      error={error}
      changed={changed}
      title="Priorities"
      onSave={formik.submitForm}
    >
      <Box component={"form"} onSubmit={formik.handleSubmit}>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="stretch"
          spacing={2}
        >
          {[0, 1, 2, 3, 4, 5].map((index: number) => {
            return (
              <Box display="flex" alignItems="center" key={index + "priority"}>
                <Box mr={2}>
                  <Typography>#{index + 1}</Typography>
                </Box>
                <TextField
                  value={(formik.values as any)[index]}
                  onChange={(e) =>
                    formik.setFieldValue(index as any, e.target.value)
                  }
                  sx={{ flex: 1 }}
                  size="small"
                />
              </Box>
            );
          })}
          <button style={{ display: "none" }} type="submit"></button>
        </Stack>
      </Box>
    </GoalSectionHolder>
  );
};
