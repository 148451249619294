import { CircularProgress } from "@mui/material";
import { useApp } from "contexts";
import { FC } from "react";
export const AppLoader: FC<{ loading?: boolean }> = ({ loading: _loading }) => {
  const { loading } = useApp();

  if (!loading && !_loading) return <></>;
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        zIndex: 10000000000,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          zIndex: 0,
          width: "100%",
          height: "100%",
          position: "absolute",
          top: 0,
          left: 0,
          backgroundColor: "black",
          opacity: 0.2,
          cursor: "not-allowed",
        }}
      ></div>
      <CircularProgress size={100} />
    </div>
  );
};
