import { FC, useEffect, useMemo, useRef, useState } from "react";
import { useFormik } from "formik";
import { useInput } from "contexts";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { GoalSectionHolder } from "components";
import { InputAdornment, Stack } from "@mui/material";
import { createValueString } from "utils";

const Goals: FC<{ formik: any }> = ({ formik }) => {
  return (
    <Box>
      <Box mb={1}>
        <Typography>Top Three Goals</Typography>
      </Box>
      <Stack spacing={1}>
        {Array(3)
          .fill("")
          .map((_, index) => {
            return (
              <TextField
                value={formik.values?.goals[index] || ""}
                key={"Goals-" + index}
                name={`goals.${index}`}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Typography color="darkgrey">
                        Goal {index + 1} :
                      </Typography>
                    </InputAdornment>
                  ),
                }}
                onChange={formik.handleChange}
                fullWidth
                variant="outlined"
                size="small"
              />
            );
          })}
      </Stack>
    </Box>
  );
};

export const Theme = () => {
  const { submitSection, goal, getGoalData } = useInput();
  const [error, setError] = useState(false);
  const timer = useRef<any>(null);
  const [initialized, setInitialized] = useState(false);

  const formik = useFormik({
    initialValues: { theme: "", help: "", goals: ["", "", ""] },
    onSubmit: (e) => {
      if (!initialized) return;
      if (changed) submitSection("misc", e);
      if (timer.current) {
        clearTimeout(timer.current);
      }
    },
  });

  const changed = useMemo(() => {
    const order = ["theme", "help", "goals"];
    try {
      if (!initialized) return false;
      if (goal.misc === undefined) return false;
      return (
        createValueString(order, formik.values) !==
        createValueString(order, goal.misc)
      );
    } catch {
      return true;
    }
  }, [formik.values, goal, initialized]);

  useEffect(() => {
    setError(false);
    if (!goal.misc) return;
    try {
      formik.setValues(goal.misc);
      setInitialized(true);
    } catch {
      setError(true);
    }
  }, [goal && goal.misc]);

  // save on exit
  useEffect(
    () => () => {
      formik.submitForm();
    },
    []
  );

  useEffect(() => {
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      if (changed) {
        formik.submitForm();
      }
    }, 5000);
  }, [formik.values, changed]);

  const steps = [
    `Fill out your yearly goals using the toggle at the top. These are goals that you would like to achieve by the end of this calendar year.`,
    `Fill out your quarterly goals by switching the toggle to quarterly. Break down your yearly (annual) goals into four quarterly goals to make them easier to reach.`,
    `Continue to complete each section until finished.`,
    `Download your PDF! You can keep this handy guide with you, bring it to events and use it on our Zoom meetings.`,
  ];

  return (
    <GoalSectionHolder
      onRefresh={getGoalData}
      error={error}
      changed={changed}
      title="Overview"
      onSave={formik.submitForm}
    >
      {`This Master Abundance Playbook or MAP for short will help you create and achieve your vision for your life and business. It will also help you set actionable goals. Remember to set goals that are SMART- Specific, Measurable, Action-Oriented, a Reach for you, and Time Sensitive. Its important that we don’t set goals that are actually outcomes. This is not just for your professional and financial goals but also your life goals like health, wealth, contribution, bucket list items and relationships. It will also help you track the numbers so you can see your progress from year to year. The app saves your data as you go.`}
      <ul style={{ display: "flex", flexDirection: "column", gap: 8 }}>
        {steps.map((step, index) => (
          <li key={"step-" + index}>
            <span style={{ fontWeight: "bold" }}>Step {index + 1}: </span>
            {step}
          </li>
        ))}
      </ul>
      <Box onSubmit={formik.handleSubmit} component={"form"} pt={3}>
        <Stack spacing={2}>
          <Box>
            <Box mb={1}>
              <Typography>Theme of the year</Typography>
            </Box>
            <TextField
              placeholder="Aligned, Content, Balanced, etc."
              name={"theme"}
              onChange={formik.handleChange}
              value={formik.values.theme || ""}
              fullWidth
              variant="outlined"
              size="small"
            />
          </Box>
          <Goals formik={formik} />
          <Box>
            <Typography mb={1}>
              What do you need help with? Can we support you?
            </Typography>
            <TextField
              name={"help"}
              onChange={formik.handleChange}
              value={formik.values.help || ""}
              fullWidth
              multiline
              minRows={5}
              variant="outlined"
              size="small"
            />
          </Box>
        </Stack>
        <button type="submit" style={{ display: "none" }}></button>
      </Box>
    </GoalSectionHolder>
  );
};
