import {
  Box,
  Button,
  FormControl,
  Input,
  InputLabel,
  Modal,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { FC, useEffect } from "react";
import * as yup from "yup";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  display: "flex",
  flexDirection: "column",
  gap: 2,
  p: 4,
};

const validationSchema = yup.object({
  name: yup.string().required().min(3),
  value: yup.number().required().moreThan(0),
});

export const Form: FC<{
  selectedItem: any;
  open: boolean;
  onClose: () => void;
  onSubmit: (value: any) => void;
  type: "assets" | "liabilities" | null;
}> = ({ open, onClose, onSubmit, type, selectedItem }) => {
  const formik = useFormik({
    initialValues: {
      name: "",
      value: "",
    },
    validateOnChange: false,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (selectedItem) {
      formik.setValues(selectedItem);
    } else {
      formik.setValues({ name: "", value: "" });
    }
  }, [selectedItem]);

  useEffect(() => {
    if (!open) {
      formik.setValues({ name: "", value: "" });
    }
  }, [open]);

  return (
    <Modal open={open} onClose={onClose}>
      <Paper
        component="form"
        onSubmit={formik.handleSubmit}
        variant="outlined"
        sx={{ ...style }}
      >
        <Typography variant="h5">
          {!!selectedItem ? "Update" : "Add"}{" "}
          {type === "assets" ? "Assets" : "Liabilities"}
        </Typography>
        <FormControl fullWidth>
          <TextField
            error={!!formik.errors.name}
            placeholder="Name"
            autoComplete="off"
            fullWidth
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            variant="outlined"
            size="small"
            prefix="$"
            helperText={formik.errors.name}
          />
        </FormControl>
        <FormControl fullWidth>
          <TextField
            error={!!formik.errors.value}
            placeholder="Value"
            autoComplete="off"
            fullWidth
            name="value"
            value={formik.values.value}
            onChange={(e) => {
              const value = e.target.value;
              if (/^[0-9]*$/.test(value)) {
                formik.handleChange(e);
              }
            }}
            variant="outlined"
            size="small"
            prefix="$"
            helperText={formik.errors.value}
          />
        </FormControl>
        <Box sx={{ display: "flex" }}>
          <Button onClick={onClose} fullWidth>
            Close
          </Button>
          <Button type="submit" fullWidth variant="contained">
            {!!selectedItem ? "Update" : "Add"}
          </Button>
        </Box>
      </Paper>
    </Modal>
  );
};
