import { Add, Delete, DeleteForever, Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { FC } from "react";
import { localizeNumber } from "utils";

export const ItemList: FC<{
  list: any[];
  onDelete: (index: number) => void;
  onEdit: (index: number) => void;
  onAdd: () => void;
  title: string;
}> = ({ list, onDelete, onAdd, title, onEdit }) => {
  return (
    <Box pt={2} sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6">{title}</Typography>
        <Button
          onClick={onAdd}
          size="small"
          startIcon={<Add />}
          variant="contained"
        >
          Add
        </Button>
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold" }}>Name</TableCell>
              <TableCell sx={{ fontWeight: "bold" }} align="right">
                Value
              </TableCell>
              <TableCell sx={{ fontWeight: "bold", width: 0 }} align="right">
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(list ? list : []).map((row, index) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell align="right">
                  $ {localizeNumber(row.value)}
                </TableCell>
                <TableCell align="right" sx={{ display: "flex" }}>
                  <IconButton onClick={() => onDelete(index)} size="small">
                    <Delete fontSize="small" />
                  </IconButton>
                  <IconButton onClick={() => onEdit(index)} size="small">
                    <Edit fontSize="small" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
