import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import { useApp } from "contexts";
import { useEffect, useMemo } from "react";
import { range } from "lodash";
import { useFormik } from "formik";
import * as yup from "yup";
import { apiCaller, showErrorToast } from "utils";
import toast from "react-hot-toast";
import FileDownload from "js-file-download";
import { QUARTERS } from "data-constants";

const validationSchema = yup.object({
  quarter: yup.number(),
  year: yup.number(),
});

const currentQuarter = () => {
  const date = new Date();
  return Math.floor(date.getMonth() / 3 + 1);
};

export const DownloadPdfPopup = () => {
  const { showDownload, setShowDownload, setLoading } = useApp();
  const currentYear = useMemo(() => new Date().getFullYear(), []);

  const formik = useFormik({
    initialValues: {
      year: currentYear,
      quarter: currentQuarter(),
    },
    validationSchema,
    validateOnMount: false,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const { year, quarter } = values;
        const data = await apiCaller.get(
          `/goals/pdf?year=${year}&quarter=${quarter}`,
          {
            responseType: "blob",
          }
        );
        FileDownload(data.data, `pdf-quarter-${quarter}-year-${year}.pdf`);
        toast.success("PDF downloaded");
        setShowDownload(false);
      } catch (err) {
        showErrorToast(err);
      } finally {
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    formik.setValues({ year: currentYear, quarter: 1 });
  }, [showDownload]);

  return (
    <Modal
      open={showDownload}
      onClose={() => setShowDownload(false)}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Paper variant="outlined" sx={{ p: 2, width: "100%", maxWidth: 300 }}>
        <Box
          component="form"
          onSubmit={formik.handleSubmit}
          py={1}
          display="flex"
          gap={2}
          flexDirection="column"
        >
          <Typography align="center" variant="h6">
            Select Quarter and Year to Download
          </Typography>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel size="small">Quarter</InputLabel>
            <Select
              error={!!formik.errors.quarter}
              fullWidth={true}
              value={formik.values.quarter}
              size="small"
              label="Quarter"
              onChange={(e) =>
                formik.setFieldValue("quarter", Number(e.target.value))
              }
            >
              {QUARTERS.map((q) => {
                return (
                  <MenuItem key={"quarter" + q.id} value={q.id}>
                    {q.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel size="small">Year</InputLabel>
            <Select
              error={!!formik.errors.year}
              value={formik.values.year}
              size="small"
              label="Year"
              onChange={(e) =>
                formik.setFieldValue("year", Number(e.target.value))
              }
            >
              {range(currentYear + 1, currentYear - 10).map((year: number) => {
                return (
                  <MenuItem key={"year" + year} value={year}>
                    {year}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <Button variant="contained" type="submit">
            DOWNLOAD
          </Button>
        </Box>
      </Paper>
    </Modal>
  );
};
