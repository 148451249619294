import { Delete } from "@mui/icons-material";
import { Box, IconButton, TextField } from "@mui/material";
import { FC } from "react";

export const ListItem: FC<{
  value: string;
  onDelete: () => void;
  onChange: (value: string) => void;
}> = ({ value, onDelete, onChange }) => {
  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <TextField
        value={value}
        placeholder="Type your bucket list item here"
        fullWidth
        variant="outlined"
        size="small"
        onChange={(e) => onChange(e.target.value)}
      />
      <IconButton onClick={onDelete}>
        <Delete />
      </IconButton>
    </Box>
  );
};
