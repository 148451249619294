import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { Toaster } from "react-hot-toast";
import { AppLoader, AppPage } from "components";
import { AppProvider } from "contexts";
import { LoginPage } from "pages/login";
import { InputPage } from "pages/input";
import { DownloadPdfPopup } from "popups/DownloadPdfPopup";

const theme = createTheme({
  typography: {
    fontFamily: "Montserrat, Arial",
    allVariants: {
      color: "white",
    },
  },
  palette: {
    mode: "dark",
    primary: {
      main: "#f2c955",
      light: "#ab9a67",
    },
    secondary: {
      main: "#44c9b7",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Toaster
          containerStyle={{
            zIndex: 9999999,
          }}
          toastOptions={{
            style: {
              zIndex: 9999999,
            },
          }}
        />
        <AppProvider>
          <AppLoader />
          <AppPage>
            <DownloadPdfPopup />
            <Routes>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/" element={<InputPage />} />
            </Routes>
          </AppPage>
        </AppProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
