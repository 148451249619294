import { FC } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useIsMobile } from "hooks";
import { TextField } from "@mui/material";

export const GoalRow: FC<{
  title: string;
  formik: any;
  currentGoal: string;
  index: number;
}> = ({ formik, currentGoal, index, title }) => {
  const { isMobile, isSmallMobile } = useIsMobile();

  return (
    <Box>
      <Typography variant="h5" pb={1} textAlign={isMobile ? "center" : "left"}>
        {title} Goal #{index + 1}
      </Typography>
      <Box
        display={"flex"}
        height={isSmallMobile ? 600 : "300px"}
        gap={2}
        flexDirection={isSmallMobile ? "column" : "row"}
      >
        <Box flex={1} height="100%">
          <TextField
            placeholder={`Please add your ${title} goal #${index + 1} here`}
            value={
              formik.values &&
              formik.values[currentGoal] &&
              formik.values[currentGoal][index]
            }
            name={`${currentGoal}.${index}`}
            onChange={formik.handleChange}
            minRows={6}
            multiline
            fullWidth
            sx={{ height: "100%" }}
          />
        </Box>
      </Box>
    </Box>
  );
};
