import { useEffect, useMemo, useRef, useState } from "react";
import { useFormik } from "formik";

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";

import { EntryRow, GoalSectionHolder, TotalCalculationRow } from "components";
import { useInput } from "contexts";

import { useIsMobile } from "hooks";
import { createValueString } from "utils";

export const HEALTH_FIELDS = [
  {
    id: "fitness",
    label: "Fitness (between 1-10)",
  },
  {
    id: "bloodPressureTop",
    label: "Blood Pressure (Top)",
    noComparison: true,
  },
  {
    id: "bloodPressureBottom",
    label: "Blood Pressure (Bottom)",
    noComparison: true,
  },
  {
    id: "bodyFatPercentage",
    label: "Body Fat %",
  },
];

export const Health = () => {
  const { submitSection, goal, getGoalData } = useInput();
  const [error, setError] = useState(false);
  const timer = useRef<any>();
  const [initialized, setInitialized] = useState(false);

  const { isSmallMobile } = useIsMobile();
  const formik = useFormik({
    initialValues: {
      [HEALTH_FIELDS[0].id]: 0,
      [HEALTH_FIELDS[1].id]: 0,
      [HEALTH_FIELDS[2].id]: 0,
      [HEALTH_FIELDS[3].id]: 0,
    },
    onSubmit: (e) => {
      if (!initialized) return;
      if (changed) submitSection("health", e);
      if (timer.current) {
        clearTimeout(timer.current);
      }
    },
  });

  useEffect(() => {
    setError(false);
    try {
      const values: any = {};
      HEALTH_FIELDS.map(({ id }) => (values[id] = 0));
      if (goal && goal.health) {
        HEALTH_FIELDS.map(({ id }) => (values[id] = goal.health[id] || 0));
      }
      formik.setValues(values);
      setInitialized(true);
    } catch (err) {
      setError(true);
    }
  }, [goal && goal.health]);

  const changed = useMemo(() => {
    if (!initialized) return false;
    const order = HEALTH_FIELDS.map(({ id }) => id);
    try {
      return (
        createValueString(order, formik.values) !==
        createValueString(order, goal && goal.health)
      );
    } catch {
      return true;
    }
  }, [goal && goal.health, formik.values]);

  useEffect(() => {
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      if (changed) {
        formik.submitForm();
      }
    }, 5000);
  }, [formik.values, changed]);

  // save on exit
  useEffect(
    () => () => {
      formik.submitForm();
    },
    []
  );

  const lhi = useMemo(() => {
    let current = 0;
    let last = 0;
    try {
      const sum = Object.values(goal.lifeHappinessIndex).reduce(
        (prev: any, curr: any) => prev + curr,
        0
      );
      const rawCurrent =
        Number(sum) / Object.keys(goal.lifeHappinessIndex).length;
      current = Number(rawCurrent.toFixed(2));
    } catch {}
    try {
      const sum = Object.values(goal._previous.lifeHappinessIndex).reduce(
        (prev: any, curr: any) => prev + curr,
        0
      );
      const rawLast =
        Number(sum) / Object.keys(goal._previous.lifeHappinessIndex).length;
      last = Number(rawLast.toFixed(2));
    } catch {}

    return { current, last };
  }, [goal]);

  const rows = HEALTH_FIELDS.map((field) => {
    return (
      <EntryRow
        noComparison={field.noComparison}
        goal={goal}
        showBox={isSmallMobile}
        key={field.id}
        id={field.id}
        label={field.label}
        section="health"
        formik={formik}
        hideAverage
      />
    );
  });

  return (
    <GoalSectionHolder
      onRefresh={getGoalData}
      error={error}
      changed={changed}
      title="Health"
      onSave={formik.submitForm}
    >
      <Box onSubmit={formik.handleSubmit} component={"form"}>
        {isSmallMobile && (
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            {rows}
            <TotalCalculationRow
              prepend=""
              showBox={true}
              yearly
              label="Life Happiness Index"
              values={lhi}
              hideAverage
            />
          </Box>
        )}
        {!isSmallMobile && (
          <Table size="small" padding="normal" sx={{ width: "100%" }}>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Current</TableCell>
                <TableCell>Last Quarter</TableCell>
                <TableCell>Difference</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows}
              <TotalCalculationRow
                prepend=""
                yearly
                label="Life Happiness Index"
                values={lhi}
                hideAverage
              />
            </TableBody>
          </Table>
        )}
        <button type="submit" style={{ display: "none" }}></button>
      </Box>
    </GoalSectionHolder>
  );
};
