import { FC } from "react";
import Box from "@mui/material/Box";
import { useIsMobile } from "hooks";
import {
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import { Warning as WarningIcon } from "@mui/icons-material";
import { useApp, useInput } from "contexts";

const SectionSelector: FC<{
  label: string;
  part: number;
  currentPart: number;
  last?: any;
  onClick: () => void;
}> = ({ part, currentPart, onClick, label }) => {
  const isActive = currentPart == part;
  return (
    <Box
      display="flex"
      alignItems="center"
      onClick={onClick}
      component="a"
      p={1}
      py={0.5}
      sx={{
        cursor: "pointer",
        transition: "all 0.4s",
        "& .label": {
          transition: "all 0.3s",
          color: currentPart == part ? "white" : "grey.600",
        },
        "& .pointer": {
          transform: "skew(-20deg)",
          transition: "all 0.3s",
          width: isActive ? 20 : 0,
          mr: isActive ? 1 : 0,
        },
        "&:hover": {
          "& .label": {
            color: !isActive ? "white" : "",
          },
          "& .pointer": {
            width: !isActive ? 10 : 20,
            mr: 1,
            backgroundColor: !isActive ? "grey.600" : "white",
          },
        },
      }}
    >
      <Box className="pointer" bgcolor="white" height="50%"></Box>
      <Typography className="label" noWrap variant="body1" fontWeight="bold">
        {label}
      </Typography>
    </Box>
  );
};

const YEARLY_SECTIONS = [
  {
    id: "overview",
    label: "Overview",
  },
  {
    id: "life-vision",
    label: "Life Vision",
  },
  {
    id: "annualGoals",
    label: "Goals",
  },
  {
    id: "mindset",
    label: "Mindset",
  },
  {
    id: "financial-growth",
    label: "Financial Growth",
  },
  {
    id: "contribution",
    label: "Contribution",
  },
  {
    id: "annual-recap",
    label: "Annual Recap",
  },
  {
    id: "net-worth-calculator",
    label: "Net Worth Calculator",
  },
  {
    id: "bucket-list",
    label: "Bucket List",
  },
  {
    id: "priorities",
    label: "Priorities",
  },
  {
    id: "mission",
    label: "Mission/Purpose and Vision",
  },
];

const QUARTERLY_SECTIONS = [
  {
    id: "topQuarterlygoals",
    label: "Goals",
  },
  { id: "lifeHappinessIndex", label: "Life Happiness Index" },
  {
    id: "health",
    label: "Health",
  },
];

const MobileSectionSelector: FC<{
  options: any;
  currentPart: any;
  setCurrentPart: any;
}> = ({ options, currentPart, setCurrentPart }) => {
  return (
    <FormControl fullWidth>
      <InputLabel size="small">Select Section</InputLabel>
      <Select
        fullWidth
        size="small"
        label="Select Section"
        value={currentPart}
        onChange={(e) => setCurrentPart(e.target.value)}
      >
        {options.map((q: any, index: number) => {
          return (
            <MenuItem key={"quarter" + q.id} value={index}>
              {q.label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

const DisabledBox = () => {
  const {
    user: { isAdmin },
  } = useApp();
  const { selectedMember } = useInput();

  if (isAdmin && !selectedMember)
    return (
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          bgcolor: "#00000070",
          width: "100%",
          height: "100%",
          zIndex: 10,
          display: "flex",
          alignItems: "start",
          pt: 10,
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#ffc558",
            color: "black",
            paddingY: 2,
            paddingX: 4,
            borderRadius: 3,
          }}
        >
          <WarningIcon fontSize="large" />
          <Typography color="inherit" variant="h4">
            DISABLED
          </Typography>
          <Typography color="inherit" variant="h6">
            Please select a member to view data
          </Typography>
        </Box>
      </Box>
    );
  return <></>;
};

export const GoalSectionNavigation: FC<{
  children: any;
  currentPart: any;
  setCurrentPart: any;
  selectedDateType: string;
}> = ({ children, currentPart, setCurrentPart, selectedDateType }) => {
  const { isMobile } = useIsMobile();

  const SECTIONS =
    selectedDateType == "quarterly" ? QUARTERLY_SECTIONS : YEARLY_SECTIONS;

  return (
    <Box
      color="white"
      pt={2}
      borderRadius={2}
      display="flex"
      height="100%"
      maxHeight="100%"
      flexDirection={isMobile ? "column" : "row"}
      position="relative"
    >
      <DisabledBox />
      {!isMobile && (
        <Box
          width={280}
          display="flex"
          flexDirection="column"
          gap={1}
          overflow="auto"
        >
          {SECTIONS.map((part, index) => (
            <SectionSelector
              key={"section" + index}
              label={part.label}
              part={index}
              currentPart={currentPart}
              onClick={() => {
                setCurrentPart(index);
              }}
            />
          ))}
        </Box>
      )}
      {isMobile && (
        <Box pb={2} width="100%">
          <MobileSectionSelector
            currentPart={currentPart}
            setCurrentPart={setCurrentPart}
            options={SECTIONS}
          />
        </Box>
      )}
      <Box
        sx={{
          overflowY: "auto",
          width: "100%",
          bgcolor: "grey.900",
          border: "1px solid",
          borderColor: "grey.800",
          borderRadius: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          position: "relative",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
