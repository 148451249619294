import { FC } from "react";

import upperCase from "lodash/upperCase";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";

import ErrorIcon from "@mui/icons-material/Error";
import RefreshIcon from "@mui/icons-material/Refresh";
import SaveIcon from "@mui/icons-material/Save";

import { useIsMobile } from "hooks";
import { ErrorBoundary } from "react-error-boundary";

const ErrorBox = () => {
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        backgroundColor: "#00000078",
        position: "absolute",
        zIndex: 1000,
        p: 2,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box sx={{ p: 2, background: "darkred", borderRadius: 2, maxWidth: 400 }}>
        <ErrorIcon fontSize="large" />
        <Typography variant="h5" pb={1}>
          Error occured in fetching data
        </Typography>
        <Typography variant="body1">
          Due to an unexpected error, the form is not working, please try again
          later.
        </Typography>
      </Box>
    </Box>
  );
};

export const GoalSectionHolder: FC<{
  children?: any;
  title: string;
  subtitle?: string;
  onSave: () => void;
  onRefresh: () => void;
  error?: boolean;
  changed?: boolean;
}> = ({ children, title, onSave, onRefresh, error, changed, subtitle }) => {
  const { isMobile } = useIsMobile();
  return (
    <ErrorBoundary
      FallbackComponent={() => <ErrorBox />}
      onReset={() => {}}
      resetKeys={["gbw-key"]}
    >
      {error && <ErrorBox />}
      <Box width="100%">
        <Box
          pb={1}
          display="flex"
          px={isMobile ? 2 : 4}
          pt={1}
          bgcolor="black"
          alignItems={"center"}
        >
          <Box flex={1} py={subtitle ? 1 : 0}>
            <Typography
              textAlign={"left"}
              variant={isMobile ? "body1" : "h4"}
              fontWeight="bold"
            >
              {upperCase(title)}
            </Typography>
            {subtitle && <Typography>{subtitle}</Typography>}
          </Box>
          <Box>
            {!isMobile ? (
              <Button
                sx={{ mr: 2 }}
                onClick={onRefresh}
                variant="outlined"
                startIcon={<RefreshIcon />}
              >
                refresh
              </Button>
            ) : (
              <IconButton onClick={onRefresh} sx={{ mr: 1 }}>
                <RefreshIcon />
              </IconButton>
            )}
            <Button
              disabled={!changed}
              variant="contained"
              size={isMobile ? "small" : "medium"}
              startIcon={<SaveIcon />}
              onClick={onSave}
            >
              save
            </Button>
          </Box>
        </Box>
        <Divider />
      </Box>
      <Box
        px={isMobile ? 2 : 4}
        my={2}
        sx={{
          height: "calc(100% - 1.5rem)",
          width: "100%",
          overflow: "auto",
        }}
      >
        {children}
      </Box>
    </ErrorBoundary>
  );
};
