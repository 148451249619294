import { Box, TextField, Typography } from "@mui/material";
import { FormikProps } from "formik";
import { FC } from "react";

export const MissionSection: FC<{ formik: FormikProps<any> }> = ({
  formik,
}) => {
  return (
    <Box>
      <Typography variant="h5">Life Purpose or Mission</Typography>
      <TextField
        value={formik.values.mission}
        onChange={(e) => formik.setFieldValue("mission", e.target.value)}
        sx={{ mt: 2 }}
        multiline
        fullWidth
        minRows={6}
      />
    </Box>
  );
};
