import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import { useApp, useInput } from "contexts";
import { GoalQuerySelector } from "components";
import { useIsMobile } from "hooks";

export const Header = () => {
  const { user } = useApp();
  const isAdmin = user.isAdmin;
  const input = useInput();
  const {
    selectedQuarter,
    selectedYear,
    selectedDateType,
    selectedMember,
    members,
  } = input;
  const { isMobile, isSmallMobile } = useIsMobile();

  const memberName = (() => {
    try {
      const member = members.find(({ id }: any) => id == selectedMember);
      return member.email;
    } catch {}
    return "No Member Selected";
  })();

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <Box flex={1} display="flex" alignItems="center" gap={2}>
        <Typography
          fontWeight="bold"
          gutterBottom
          variant={isMobile ? "h6" : "h4"}
          color="white"
          flex={isSmallMobile ? "1" : ""}
        >
          MAP Update
        </Typography>
        <Box display="flex" alignItems="end" flexDirection="column">
          <Typography
            variant={isMobile ? "body2" : "body1"}
            border="1px solid"
            borderColor="grey.700"
            p={0.2}
            px={1}
            mb={isSmallMobile ? 1 : 0}
            borderRadius={1}
            fontWeight="bold"
          >
            {isAdmin && `${memberName} | `}
            {(selectedDateType || "").toUpperCase()}{" "}
            {selectedDateType == "quarterly" ? "Q" + selectedQuarter + "-" : ""}
            {selectedYear}
          </Typography>
          {isSmallMobile && (
            <Box>
              <GoalQuerySelector {...input} isMobile={isMobile} />
            </Box>
          )}
        </Box>
      </Box>
      {!isSmallMobile && <GoalQuerySelector {...input} isMobile={isMobile} />}
    </Box>
  );
};
