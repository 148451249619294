import { FC } from "react";

export const ConditionalView: FC<{
  currentValue: string;
  expectedValue: string;
  children: any;
}> = ({ currentValue, expectedValue, children }) => {
  if (expectedValue !== currentValue) return <></>;
  return <>{children}</>;
};
