import { FC, createContext, useContext, useEffect, useState } from "react";
import { apiCaller, showErrorToast } from "utils";
import { useApp } from "./appContext";
import toast from "react-hot-toast";

export const InputContext = createContext<{
  selectedDateType: string;
  selectedYear: number;
  selectedQuarter: number;
  currentYear: number;
  setSelectedQuarter: (i: number) => any;
  setSelectedDateType: (i: string) => any;
  setSelectedYear: (i: number) => any;
  getGoalData: () => any;
  submitSection: (sectionId: string, data: any) => any;
  goal: any;
  selectedMember: number;
  members: any;
  setSelectedMember: (i: number) => any;
}>({
  selectedDateType: "",
  selectedYear: 0,
  selectedQuarter: 0,
  currentYear: 0,
  setSelectedDateType: () => {},
  setSelectedQuarter: () => {},
  setSelectedYear: () => {},
  getGoalData: () => {},
  submitSection: () => {},
  goal: {},
  selectedMember: 0,
  setSelectedMember: () => {},
  members: [],
});

export const InputProvider: FC<{ children: any }> = ({ children }) => {
  const [selectedMember, setSelectedMember] = useState<any>(0);
  const [selectedYear, setSelectedYear] = useState<any>(0);
  const [selectedQuarter, setSelectedQuarter] = useState<any>(0);
  const [selectedDateType, setSelectedDateType] = useState("yearly");
  const [currentYear, setCurrentYear] = useState<any>(0);
  const [goal, setGoal] = useState<any>({});
  const [members, setMembers] = useState<any>([]);
  const { setLoading, user } = useApp();

  const isAdmin = user.isAdmin;

  const fetchMembers = async () => {
    try {
      const {
        data: { rows },
      } = await apiCaller.get(`/members`);
      setMembers(rows);
    } catch (err) {
      showErrorToast(err);
    }
  };

  useEffect(() => {
    if (isAdmin) fetchMembers();
  }, [isAdmin]);

  useEffect(() => {
    const now = new Date();
    const currentYear = now.getUTCFullYear();
    const currentQuarter = Math.floor((now.getUTCMonth() + 3) / 3);
    setCurrentYear(currentYear);
    setSelectedYear(currentYear);
    setSelectedQuarter(currentQuarter);
  }, []);

  useEffect(() => {
    if (isAdmin && !selectedMember) return;
    if (selectedQuarter != 0 && selectedYear != 0) getGoalData();
  }, [
    selectedQuarter,
    selectedYear,
    selectedDateType,
    isAdmin,
    selectedMember,
  ]);

  const getGoalData = async () => {
    if (isAdmin && !selectedMember) return;
    setLoading(true);
    try {
      let query = `/goals/${selectedDateType}?quarter=${selectedQuarter}&year=${selectedYear}`;
      if (isAdmin) {
        query += `&memberId=${selectedMember}`;
      }
      const {
        data: { data },
      } = await apiCaller.get(query);
      setGoal(data);
    } catch (err) {
      showErrorToast(err);
    }
    setLoading(false);
  };

  const submitSection = async (sectionId: string, data: any) => {
    setLoading(true);
    try {
      const postData = {
        quarter: selectedQuarter,
        year: selectedYear,
        [sectionId]: data,
      };

      if (user.isAdmin) postData.memberId = selectedMember;

      const {
        data: { data: response },
      } = await apiCaller.post("/goals/" + sectionId, postData);
      let newGoal = { ...goal };
      if (response && response[sectionId]) {
        newGoal[sectionId] = response[sectionId];
      }
      if (response._averages) {
        newGoal._averages[sectionId] = response._averages[sectionId];
      }
      setGoal(newGoal);
      toast.success("Goal has been successfully updated!", {
        id: "goal-updated",
      });
    } catch (err) {
      showErrorToast(err);
    }
    setLoading(false);
  };

  return (
    <InputContext.Provider
      value={{
        setSelectedDateType,
        selectedDateType,
        selectedYear,
        selectedQuarter,
        currentYear,
        setSelectedQuarter,
        setSelectedYear,
        getGoalData,
        submitSection,
        goal,
        selectedMember,
        setSelectedMember,
        members,
      }}
    >
      {children}
    </InputContext.Provider>
  );
};

export const useInput = () => useContext(InputContext);
