import { useEffect, useMemo, useRef, useState } from "react";
import { useFormik } from "formik";

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";

import {
  GoalSectionHolder,
  TotalCalculationRow,
  EntryRow,
  EntrySecondaryRow,
} from "components";

import { useInput } from "contexts";
import { useIsMobile } from "hooks";
import { createValueString } from "utils";

const FIELDS = [
  {
    id: "passiveIncome",
    label: "Passive Income (Rents/Distributions)",
  },
  {
    id: "activeIncome",
    label: "Active Income (Wages)",
  },
  {
    id: "personalExpenses",
    label: "Personal Expenses",
  },
  {
    id: "personalTaxes",
    label: "Personal Taxes",
  },
  {
    id: "actualInvested",
    label: "Actual Invested",
  },
];

export const FinancialGrowth = () => {
  const { submitSection, goal, getGoalData } = useInput();
  const [error, setError] = useState(false);
  const { isSmallMobile } = useIsMobile();
  const [initialized, setInitialized] = useState(false);

  const timer = useRef<any>();

  const formik = useFormik({
    initialValues: {
      [FIELDS[0].id]: 0,
      [FIELDS[1].id]: 0,
      [FIELDS[2].id]: 0,
      [FIELDS[3].id]: 0,
      [FIELDS[4].id]: 0,
      goal: {
        [FIELDS[0].id]: 0,
        [FIELDS[1].id]: 0,
        [FIELDS[2].id]: 0,
        [FIELDS[3].id]: 0,
        [FIELDS[4].id]: 0,
      },
    },
    onSubmit: (e) => {
      if (!initialized) return;
      if (changed) submitSection("financialGrowth", e);
      if (timer.current) {
        clearTimeout(timer.current);
      }
    },
  });

  const changed = useMemo(() => {
    const order = FIELDS.map(({ id }) => id);
    if (!initialized) return false;
    try {
      if (
        createValueString(order, formik.values.goal) !==
        createValueString(order, goal && goal.financialGrowth.goal)
      ) {
        return true;
      }
      return (
        createValueString(order, formik.values) !==
        createValueString(order, goal && goal.financialGrowth)
      );
    } catch {
      return true;
    }
  }, [formik.values, goal && goal.financialGrowth]);

  useEffect(() => {
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      if (changed) {
        formik.submitForm();
      }
    }, 5000);
  }, [formik.values, changed]);

  useEffect(() => {
    setError(false);
    if (!goal.financialGrowth) return;
    try {
      const values: any = { goal: {} };
      FIELDS.map(({ id }) => {
        values[id] = 0;
        values.goal[id] = 0;
      });
      if (goal && goal.financialGrowth) {
        FIELDS.map(({ id }) => (values[id] = goal.financialGrowth[id] || 0));
        if (goal.financialGrowth.goal) {
          FIELDS.map(
            ({ id }) => (values.goal[id] = goal.financialGrowth.goal[id] || 0)
          );
        }
      }
      formik.setValues(values);
      setInitialized(true);
    } catch {
      setError(true);
    }
  }, [goal && goal.financialGrowth]);

  // save on exit
  useEffect(
    () => () => {
      formik.submitForm();
    },
    []
  );

  const {
    averageTotalIncome,
    averageTotalTaxesAndExpenses,
    averageFinancialFreedomPercentage,
  } = useMemo(() => {
    let averageTotalIncome = 0;
    let averageTotalTaxesAndExpenses = 0;
    let averageFinancialFreedomPercentage = 0;
    try {
      if (goal._averages && goal._averages.financialGrowth) {
        const { activeIncome, passiveIncome } = goal._averages.financialGrowth;
        averageTotalIncome = +activeIncome + +passiveIncome;
      }
    } catch {}
    try {
      if (goal._averages && goal._averages.financialGrowth) {
        const { personalExpenses, personalTaxes } =
          goal._averages.financialGrowth;
        averageTotalTaxesAndExpenses = +personalExpenses + +personalTaxes;
      }
    } catch {}
    try {
      if (goal._averages && goal._averages.financialGrowth) {
        const { passiveIncome } = goal._averages.financialGrowth;
        averageFinancialFreedomPercentage =
          (100 * +passiveIncome) / +averageTotalTaxesAndExpenses;
      }
    } catch {}

    return {
      averageTotalIncome: Number(averageTotalIncome.toFixed(2)),
      averageTotalTaxesAndExpenses: Number(
        averageTotalTaxesAndExpenses.toFixed(2)
      ),
      averageFinancialFreedomPercentage: Number(
        averageFinancialFreedomPercentage.toFixed(2)
      ),
    };
  }, [goal && goal._averages, formik.values]);

  const {
    currentTotalIncome,
    currentTotalTaxesAndExpenses,
    currentFinancialFreedomPercentage,
  } = useMemo(() => {
    let currentTotalIncome = 0;
    let currentTotalTaxesAndExpenses = 0;
    let currentFinancialFreedomPercentage = 0;
    try {
      const { activeIncome, passiveIncome } = formik.values;
      currentTotalIncome = +activeIncome + +passiveIncome;
    } catch {}
    try {
      const { personalExpenses, personalTaxes } = formik.values;
      currentTotalTaxesAndExpenses = +personalExpenses + +personalTaxes;
    } catch {}
    try {
      const { passiveIncome } = formik.values;
      currentFinancialFreedomPercentage =
        (100 * +passiveIncome) / +currentTotalTaxesAndExpenses;
    } catch {}
    return {
      currentTotalIncome: Number(currentTotalIncome.toFixed(2)),
      currentTotalTaxesAndExpenses: Number(
        currentTotalTaxesAndExpenses.toFixed(2)
      ),
      currentFinancialFreedomPercentage: Number(
        currentFinancialFreedomPercentage.toFixed(2)
      ),
    };
  }, [formik.values]);

  const {
    lastTotalIncome,
    lastTotalTaxesAndExpenses,
    lastFinancialFreedomPercentage,
    lastNetWorth,
  } = useMemo(() => {
    let lastTotalIncome = 0;
    let lastTotalTaxesAndExpenses = 0;
    let lastFinancialFreedomPercentage = 0;
    let lastNetWorth = 0;

    try {
      const { assets, liabilities } = goal.netWorthCalculator.goals;
      lastNetWorth = assets - liabilities;
    } catch {}

    try {
      const { activeIncome, passiveIncome } = formik.values.goal;
      lastTotalIncome = +activeIncome + +passiveIncome;
    } catch {}

    try {
      const { personalExpenses, personalTaxes } = formik.values.goal;
      lastTotalTaxesAndExpenses = +personalExpenses + +personalTaxes;
    } catch {}
    try {
      const { passiveIncome } = formik.values.goal;
      lastFinancialFreedomPercentage =
        (100 * +passiveIncome) / +lastTotalTaxesAndExpenses;
    } catch {}
    return {
      lastTotalIncome: Number(lastTotalIncome.toFixed(2)),
      lastTotalTaxesAndExpenses: Number(lastTotalTaxesAndExpenses.toFixed(2)),
      lastFinancialFreedomPercentage: Number(
        lastFinancialFreedomPercentage.toFixed(2)
      ),
      lastNetWorth: Number(lastNetWorth.toFixed(2)),
    };
  }, [goal && formik.values]);

  const { currentNetWorth } = useMemo(() => {
    let currentNetWorth = 0;
    try {
      const assets = (
        goal.netWorthCalculator.assets as { value: number }[]
      ).reduce((prev, curr) => prev + curr.value, 0);
      const liabilities = (
        goal.netWorthCalculator.liabilities as { value: number }[]
      ).reduce((prev, curr) => prev + curr.value, 0);
      currentNetWorth = assets - liabilities;
    } catch {}
    return {
      currentNetWorth,
    };
  }, [goal && goal.netWorthCalculator]);

  const totalIncome = {
    average: averageTotalIncome,
    current: currentTotalIncome,
    last: lastTotalIncome,
  };

  const totalExpensesAndTaxes = {
    current: currentTotalTaxesAndExpenses,
    last: lastTotalTaxesAndExpenses,
    average: averageTotalTaxesAndExpenses,
  };

  const financialFreedomPercentage = {
    current: currentFinancialFreedomPercentage,
    last: lastFinancialFreedomPercentage,
    average: averageFinancialFreedomPercentage,
  };

  const netWorth = {
    current: currentNetWorth,
    last: lastNetWorth,
    average: 0,
  };

  const rows = (
    <>
      <EntrySecondaryRow
        goal={goal}
        reverseDifference
        showBox={isSmallMobile}
        prepend="$"
        section="financialGrowth"
        formik={formik}
        {...FIELDS[0]}
      />
      <EntrySecondaryRow
        reverseDifference
        goal={goal}
        showBox={isSmallMobile}
        prepend="$"
        section="financialGrowth"
        formik={formik}
        {...FIELDS[1]}
      />
      <TotalCalculationRow
        isGoal
        reverseDifference
        showBox={isSmallMobile}
        label="Total Gross Annual Income"
        values={totalIncome}
      />
      <EntrySecondaryRow
        reverseDifference
        goal={goal}
        showBox={isSmallMobile}
        prepend="$"
        section="financialGrowth"
        formik={formik}
        {...FIELDS[2]}
      />
      <EntrySecondaryRow
        reverseDifference
        goal={goal}
        showBox={isSmallMobile}
        prepend="$"
        section="financialGrowth"
        formik={formik}
        {...FIELDS[3]}
      />
      <TotalCalculationRow
        reverseDifference
        showBox={isSmallMobile}
        label="Total Annual Expenses & Taxes"
        values={totalExpensesAndTaxes}
      />

      <TotalCalculationRow
        reverseDifference
        isGoal
        showBox={isSmallMobile}
        label="Left To Invest"
        values={{
          current: totalIncome.current - totalExpensesAndTaxes.current,
          last: totalIncome.last - totalExpensesAndTaxes.last,
          average: totalIncome.average - totalExpensesAndTaxes.average,
        }}
      />
      <EntrySecondaryRow
        reverseDifference
        goal={goal}
        showBox={isSmallMobile}
        section="financialGrowth"
        formik={formik}
        prepend="$"
        {...FIELDS[4]}
      />
      <TotalCalculationRow
        reverseDifference
        isGoal
        showBox={isSmallMobile}
        prepend="$"
        postpend=""
        label="Net Worth"
        values={netWorth}
      />

      <TotalCalculationRow
        reverseDifference
        isGoal
        showBox={isSmallMobile}
        prepend=""
        postpend="%"
        label="Financial Freedom %"
        sublabel="Spend covered by Passive Income"
        values={financialFreedomPercentage}
      />
    </>
  );

  return (
    <GoalSectionHolder
      onRefresh={getGoalData}
      changed={changed}
      error={error}
      title="FinancialGrowth"
      subtitle="Annual Income and Expenses"
      onSave={formik.submitForm}
    >
      <Box onSubmit={formik.handleSubmit} component={"form"}>
        {isSmallMobile && (
          <Box display="flex" gap={2} flexDirection="column">
            {rows}
          </Box>
        )}
        {!isSmallMobile && (
          <Table size="small" padding="normal" sx={{ width: "100%" }}>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Last Year</TableCell>
                <TableCell>Current Goal</TableCell>
                <TableCell>Difference</TableCell>
                <TableCell>Running Total</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{rows}</TableBody>
          </Table>
        )}
        <button type="submit" style={{ display: "none" }}></button>
      </Box>
    </GoalSectionHolder>
  );
};
