import {
  Box,
  Card,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useInput } from "contexts";
import { FormikProps, useFormik } from "formik";
import { FC } from "react";
import { localizeNumber } from "utils";

const InputRow: FC<{ label: string; id: string; formik: FormikProps<any> }> = ({
  label,
  id,
  formik,
}) => {
  const name = `goals.${id}`;

  return (
    <TextField
      autoComplete="off"
      name={name}
      onChange={(e) => {
        const value = Number(e.target.value);
        if (isNaN(Number(value))) return;
        if (Number(value) < 0) return;
        formik.setFieldValue(name, value);
      }}
      value={(formik.values as any)["goals"][id]}
      fullWidth
      variant="outlined"
      size="small"
      InputProps={{
        startAdornment: <InputAdornment position="start">$</InputAdornment>,
      }}
    />
  );
};

export const Goals: FC<{ formik: FormikProps<any> }> = ({ formik }) => {
  const { currentYear } = useInput();

  const currentAssets = (() => {
    try {
      return formik.values.assets.reduce(
        (prev: any, curr: any) => curr.value + prev,
        0
      );
    } catch {
      return 0;
    }
  })();
  const currentLiabilities = (() => {
    try {
      return formik.values.liabilities.reduce(
        (prev: any, curr: any) => curr.value + prev,
        0
      );
    } catch {
      return 0;
    }
  })();

  return (
    <Box pt={2} sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
      <Card
        variant="outlined"
        sx={{
          p: 2,
          gap: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography>What are your goals for {currentYear}?</Typography>
        <TableContainer sx={{ mt: 2 }} component={Paper} variant="elevation">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Current</TableCell>
                <TableCell>Goal</TableCell>
              </TableRow>
            </TableHead>
            <TableRow>
              <TableCell>Assets</TableCell>
              <TableCell>$ {currentAssets}</TableCell>
              <TableCell>
                <InputRow label="Assets" formik={formik} id="assets" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Liabilities</TableCell>
              <TableCell>$ {currentLiabilities}</TableCell>
              <TableCell>
                <InputRow
                  label="Liabilities"
                  formik={formik}
                  id="liabilities"
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Net Worth</TableCell>
              <TableCell>$ {currentAssets - currentLiabilities}</TableCell>
              <TableCell>
                $ {formik.values.goals.assets - formik.values.goals.liabilities}
              </TableCell>
            </TableRow>
          </Table>
        </TableContainer>
      </Card>
    </Box>
  );
};
