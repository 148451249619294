import { useEffect, useState, FC, useMemo, useRef } from "react";
import { useFormik } from "formik";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Checkbox from "@mui/material/Checkbox";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import TableHead from "@mui/material/TableHead";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import { GoalSectionHolder } from "components";
import { useInput } from "contexts";
import { useIsMobile } from "hooks";
import { createValueString } from "utils";

const GOAL_MAP = {
  0: "high",
  1: "upcoming",
  2: "bucketList",
};

const DATA_FORMAT = [
  { checked: false, value: "" },
  { checked: false, value: "" },
  { checked: false, value: "" },
  { checked: false, value: "" },
  { checked: false, value: "" },
];

const GoalTable: FC<{ tab: number; formik: any; alternate?: boolean }> = ({
  tab,
  formik,
  alternate,
}) => {
  const key = (GOAL_MAP as any)[tab];
  const data: any = formik.values[key];

  return (
    <TableContainer sx={{ mt: 2 }} component={Paper} variant="elevation">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>{alternate ? "Goals" : "Goals Accomplished"}</TableCell>
            {alternate && <TableCell width={0}>Accomplished?</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {data &&
            Object.keys(data).map((_, index) => {
              return (
                <TableRow key={"table" + tab + "" + index}>
                  <TableCell width={10}>
                    <Typography>#{index + 1}</Typography>
                  </TableCell>
                  <TableCell>
                    <TextField
                      name={`${key}.${index}.value`}
                      onChange={formik.handleChange}
                      value={formik.values[key][index].value}
                      size="small"
                      fullWidth
                      placeholder="Type your goal here"
                    />
                  </TableCell>
                  {alternate && (
                    <TableCell align="center" width={10}>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Checkbox
                          name={`${key}.${index}.checked`}
                          checked={formik.values[key][index]["checked"]}
                          onChange={(_, checked) => {
                            formik.setFieldValue(
                              `${key}.${index}.checked`,
                              checked
                            );
                          }}
                        />
                      </Box>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const VIEWS = [
  "Top 5 Highs (Last Year)",
  "Top 5 Upcoming Goals",
  "Top 5 Bucket List Items",
];

export const AnnualRecap = () => {
  const [tab, setTab] = useState(0);
  const { goal, submitSection, getGoalData } = useInput();
  const [error, setError] = useState(false);
  const { isSmallMobile } = useIsMobile();
  const timer = useRef<any>();
  const [initialized, setInitialzed] = useState(false);

  const formik = useFormik({
    initialValues: {
      high: DATA_FORMAT,
      upcoming: DATA_FORMAT,
      bucketList: DATA_FORMAT,
    },
    onSubmit: (e) => {
      if (!initialized) return;
      if (changed) submitSection("annualRecap", e);
      if (timer.current) {
        clearTimeout(timer.current);
      }
    },
  });

  useEffect(() => {
    setError(false);
    try {
      const data = goal && goal.annualRecap;
      if (!data) return;
      formik.setValues(data);
      setInitialzed(true);
    } catch {
      setError(true);
    }
  }, [goal && goal.annualRecap]);

  const changed = useMemo(() => {
    const order = Object.values(GOAL_MAP);
    if (!initialized) return false;
    try {
      return (
        createValueString(order, formik.values) !==
        createValueString(order, goal && goal.annualRecap)
      );
    } catch {
      return true;
    }
  }, [formik.values, goal && goal.annualRecap]);

  useEffect(() => {
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      if (changed) {
        formik.submitForm();
      }
    }, 5000);
  }, [formik.values, changed]);

  // save on exit
  useEffect(
    () => () => {
      formik.submitForm();
    },
    []
  );

  return (
    <GoalSectionHolder
      onRefresh={getGoalData}
      error={error}
      changed={changed}
      title="Annual Recap"
      onSave={formik.submitForm}
    >
      {!isSmallMobile && (
        <Tabs
          scrollButtons="auto"
          value={tab}
          onChange={(e, newTab) => setTab(newTab)}
        >
          {VIEWS.map((view) => (
            <Tab label={view} key={"tab" + view} />
          ))}
        </Tabs>
      )}
      {isSmallMobile && (
        <Select
          fullWidth
          size="small"
          value={tab}
          onChange={(e) => setTab(Number(e.target.value))}
        >
          {VIEWS.map((view, index) => (
            <MenuItem value={index} key={"select" + view}>
              {view}
            </MenuItem>
          ))}
        </Select>
      )}
      <Box component={"form"} onSubmit={formik.handleSubmit}>
        <GoalTable tab={tab} formik={formik} alternate={tab !== 0} />
        <button style={{ display: "none" }} type="submit"></button>
      </Box>
    </GoalSectionHolder>
  );
};
