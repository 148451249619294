import { useState, useEffect, FC } from "react";
import { range } from "lodash";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import {
  Autocomplete,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { useApp } from "contexts";
import { QUARTERS } from "data-constants";

export const GoalQuerySelector: FC<any> = (props) => {
  const { user } = useApp();
  const {
    currentYear,
    selectedYear,
    selectedQuarter,
    selectedDateType,
    setSelectedQuarter,
    setSelectedYear,
    setSelectedDateType,
    isMobile,
    selectedMember,
    setSelectedMember,
    members,
  } = props;
  const [year, setYear] = useState(selectedYear);
  const [quarter, setQuarter] = useState(selectedQuarter);
  const [dateType, setDateType] = useState(selectedDateType);
  const [member, setMember] = useState(selectedMember);
  const [showModal, setShowModal] = useState(false);

  const isAdmin = user.isAdmin;

  useEffect(() => {
    if (!year) {
      setYear(currentYear);
    }
    setQuarter(selectedQuarter);
  }, [currentYear, selectedQuarter]);

  useEffect(() => {
    setYear(selectedYear);
    setQuarter(selectedQuarter);
    setDateType(selectedDateType);
    setMember(selectedMember);
  }, [showModal]);

  const updateQuarter = (query: {
    quarter?: number;
    dateType?: string;
    member?: string;
    year?: number;
  }) => {
    setSelectedQuarter(query.quarter || quarter);
    setSelectedYear(query.year || year);
    setSelectedDateType(query.dateType || dateType);
    setSelectedMember(query.member || member);
  };

  const showPopupForm = isMobile || isAdmin;

  const form = (
    <Box
      py={1}
      display="flex"
      flexDirection={showPopupForm ? "column" : "row"}
      gap={2}
    >
      {isAdmin && (
        <FormControl sx={{ width: showPopupForm ? "100%" : 250 }}>
          <Autocomplete
            value={member}
            getOptionLabel={(value) => {
              const member = members.find(({ id }: any) => id === value);
              if (member) return member.displayName + " - " + member.email;
              return "";
            }}
            onChange={(e, value: any) => {
              setMember(value);
              updateQuarter({ member: value });
            }}
            options={members.map(({ id }: any) => id)}
            renderInput={(params) => <TextField {...params} label="Member" />}
          />
        </FormControl>
      )}
      <FormControl>
        <ToggleButtonGroup
          size="small"
          color="primary"
          value={dateType}
          onChange={(e, val) => {
            if (val) {
              setDateType(val);
              updateQuarter({ dateType: val });
            }
          }}
          fullWidth={showPopupForm}
          exclusive
        >
          <ToggleButton value="yearly">1. Yearly</ToggleButton>
          <ToggleButton value="quarterly">2. Quarterly</ToggleButton>
        </ToggleButtonGroup>
      </FormControl>
      <FormControl
        disabled={dateType === "yearly"}
        sx={{ width: showPopupForm ? "100%" : 250 }}
      >
        <InputLabel size="small">Quarter</InputLabel>
        <Select
          fullWidth={showPopupForm}
          value={quarter}
          size="small"
          label="Quarter"
          onChange={(e) => {
            const val = Number(e.target.value);
            setQuarter(val);
            updateQuarter({ quarter: val });
          }}
        >
          {QUARTERS.map((q) => {
            return (
              <MenuItem key={"quarter" + q.id} value={q.id}>
                {q.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <FormControl sx={{ width: showPopupForm ? "100%" : 150 }}>
        <InputLabel size="small">Year</InputLabel>
        <Select
          value={year}
          size="small"
          label="Year"
          onChange={(e) => {
            const val = Number(e.target.value);
            setYear(val);
            updateQuarter({ year: val });
          }}
        >
          {range(currentYear + 1, currentYear - 10).map((year: number) => {
            return (
              <MenuItem key={"year" + year} value={year}>
                {year}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <button type="submit" style={{ display: "none" }} />
    </Box>
  );

  if (showPopupForm)
    return (
      <>
        <Button
          onClick={() => setShowModal(true)}
          variant="contained"
          size="small"
        >
          CHANGE FORM
        </Button>
        <Modal
          open={showModal}
          onClose={() => setShowModal(false)}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Paper variant="outlined" sx={{ p: 2, width: "100%", maxWidth: 300 }}>
            {form}
            <Button
              onClick={() => setShowModal(false)}
              variant="text"
              color="warning"
              fullWidth
            >
              Close Form
            </Button>
          </Paper>
        </Modal>
      </>
    );

  return form;
};
