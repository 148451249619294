import { FC, createContext, useContext, useEffect, useState } from "react";
import { apiCaller, showErrorToast } from "utils";
import { useLocation, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { AppLoader } from "components";

export const AppContext = createContext<{
  loading: boolean;
  sessionChecked: boolean;
  setLoading: any;
  user: any;
  setUser: any;
  setSessionChecked: any;
  logout: any;
  adminLoginMode: boolean;
  loginByEmail: any;
  showDownload: boolean;
  setShowDownload: any;
}>({
  adminLoginMode: false,
  loading: false,
  user: false,
  setLoading: false,
  sessionChecked: false,
  setUser: false,
  setSessionChecked: false,
  logout: false,
  loginByEmail: false,
  showDownload: false,
  setShowDownload: false,
});

export const AppProvider: FC<{ children: any }> = ({ children }) => {
  const [user, setUser] = useState<any>(false);
  const [loading, setLoading] = useState(true);
  const [sessionChecked, setSessionChecked] = useState(false);
  const [adminLoginMode, setAdminLoginMode] = useState(false);
  const [showDownload, setShowDownload] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const logout = async () => {
    setLoading(true);
    try {
      await apiCaller.post("/auth/logout");
      setUser(false);
    } catch (err) {
      showErrorToast(err);
    }
    setLoading(false);
  };

  const checkSession = async () => {
    setLoading(true);
    setSessionChecked(false);
    try {
      const {
        data: {
          data: { user },
        },
      } = await apiCaller.post("/auth/check");
      setUser(user);
    } catch {}
    setLoading(false);
    setSessionChecked(true);
  };

  const loginByEmail = async (email: string, password: string) => {
    setLoading(true);
    try {
      const {
        data: {
          data: { user },
        },
      } = await apiCaller.post("/auth/login", {
        email,
        password,
      });
      setUser(user);
    } catch (err) {
      showErrorToast(err);
    }
    setSessionChecked(true);
    setLoading(false);
  };

  const loginByCode = async (code: string) => {
    setLoading(true);
    try {
      const {
        data: {
          data: { user },
        },
      } = await apiCaller.post("/auth/login/link?code=" + code);
      setUser(user);
    } catch (err) {
      showErrorToast(err);
    }
    setSessionChecked(true);
    setLoading(false);
  };

  useEffect(() => {
    checkSession();
  }, []);

  useEffect(() => {
    if (sessionChecked && user) {
      if (location.pathname == "/login") {
        navigate("/");
      }
    } else if (sessionChecked && !user) {
      navigate("/login");
    }

    const code = new URLSearchParams(location.search).get("_dm");
    const type = new URLSearchParams(location.search).get("type");
    if (type && type == "admin") {
      setAdminLoginMode(true);
    } else {
      setAdminLoginMode(false);
    }
    if (code && sessionChecked && !user) {
      loginByCode(code);
    }
  }, [user, sessionChecked]);

  useEffect(() => {
    if (sessionChecked && user) {
      if (location.pathname == "/login") {
        navigate("/");
      }
    } else if (sessionChecked && !user) {
      navigate("/login");
    }
  }, [location.pathname]);

  if (!sessionChecked) {
    return (
      <Box bgcolor="black" width="100vw" height="100vh">
        <AppLoader loading={true} />
      </Box>
    );
  }

  return (
    <AppContext.Provider
      value={{
        loading,
        setLoading,
        user,
        sessionChecked,
        setUser,
        setSessionChecked,
        logout,
        adminLoginMode,
        loginByEmail,
        showDownload,
        setShowDownload,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useApp = () => useContext(AppContext);
