import {
  List,
  ListItem,
  Box,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Add, Task, Download, Support } from "@mui/icons-material";
import { useApp } from "contexts";
import { useLocation, useNavigate } from "react-router-dom";
import { useMemo } from "react";

export const Navigation = () => {
  const {
    user: { isAdmin, isSuperAdmin },
    setShowDownload,
  } = useApp();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const menuItemsGenerator = (
    isAdmin: boolean,
    isSuperAdmin: boolean
  ): any[] => {
    if (isAdmin && !isSuperAdmin) {
      return [
        {
          id: "/",
          label: "Goal View",
          Icon: Task,
        },
      ];
    }
    if (isAdmin && isSuperAdmin) {
      return [
        {
          id: "/",
          label: "Goal View",
          Icon: Task,
        },

        // {
        //   id: "/users",
        //   label: "Users",
        //   Icon: Person,
        // },
      ];
    }

    return [
      {
        id: "/",
        label: "MAP Update",
        Icon: Add,
      },
      {
        id: "/pdf",
        label: "PDF Download",
        Icon: Download,
        onClick: () => {
          setShowDownload(true);
        },
      },
      {
        external: true,
        id: "https://gobundancewomen.com/support/",
        label: "Support",
        Icon: Support,
      },
    ];
  };

  const menuItems = useMemo(
    () => menuItemsGenerator(isAdmin, isSuperAdmin),
    [isAdmin, isSuperAdmin]
  );

  return (
    <List>
      {menuItems.map(({ id, label, Icon, onClick, external }) => {
        const isActive = id === pathname;
        const style = isActive ? { backgroundColor: "grey.800" } : {};
        return (
          <ListItem
            key={id}
            disablePadding
            sx={{ position: "relative", ...style }}
            onClick={() => {
              if (onClick) {
                return onClick();
              }
              if (external) {
                return window && window.open(id, "_blank")?.focus();
              }
              if (id) {
                navigate(id);
              }
            }}
          >
            {isActive && (
              <Box
                sx={{
                  position: "absolute",
                  height: "100%",
                  width: "5px",
                  backgroundColor: "primary.main",
                  right: 0,
                  top: 0,
                }}
              />
            )}
            <ListItemButton>
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText primary={label.toUpperCase()} />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
};
