import { FC } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useIsMobile } from "hooks";

export const VisionInput: FC<{
  id: string;
  formik: any;
  title: string;
  subtitle: string;
}> = ({ title, subtitle, id, formik }) => {
  const { isMobile } = useIsMobile();
  return (
    <Box>
      <Box paddingBottom={1}>
        <Typography variant="body1">{title}</Typography>
      </Box>
      <Box>
        <TextField
          multiline={isMobile}
          minRows={2}
          name={id}
          value={formik.values[id] || ""}
          onChange={formik.handleChange}
          placeholder={subtitle}
          type=""
          fullWidth
          size="small"
          autoComplete="off"
        />
      </Box>
    </Box>
  );
};
