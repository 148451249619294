export const makeItFinite = (num: number, replace?: number) => {
  return Number.isFinite(num) ? num : replace || 0;
};

export const localizeNumber = (num: number | string) => {
  try {
    const _num = Number(num).toLocaleString("en-US");
    if (isNaN(Number(num))) return "-";
    return _num;
  } catch {}
  return num;
};

export const createValueString = (order: string[], value: any) => {
  let text = "";
  order.forEach((o) => (text += JSON.stringify(value[o])));
  return text;
};
