import { useFormik } from "formik";
import { useEffect, useMemo, useRef, useState } from "react";

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";

import { EntrySecondaryRow, GoalSectionHolder } from "components";
import { useInput } from "contexts";

import { useIsMobile } from "hooks";
import { createValueString } from "utils";

const FIELDS = [
  {
    id: "hours",
    label: "Contribution Hours",
    sublabel: "(Hourly rate x Number of hours contributed)",
  },
  {
    id: "dollars",
    label: "Contribution Dollars",
  },
  {
    id: "total",
    label: "Total Contribution",
  },
];

export const Contribution = () => {
  const { submitSection, goal, getGoalData } = useInput();
  const [error, setError] = useState(false);
  const { isSmallMobile } = useIsMobile();
  const [initialized, setInitialized] = useState(false);
  const timer = useRef<any>();

  const formik = useFormik({
    initialValues: {
      [FIELDS[0].id]: 0,
      [FIELDS[1].id]: 0,
      [FIELDS[2].id]: 0,
      goal: {
        [FIELDS[0].id]: 0,
        [FIELDS[1].id]: 0,
        [FIELDS[2].id]: 0,
      },
    },
    onSubmit: (e) => {
      if (!initialized) return;
      if (changed) submitSection("contribution", e);
      if (timer.current) {
        clearTimeout(timer.current);
      }
    },
  });

  useEffect(() => {
    setError(false);
    try {
      const values: any = { goal: {} };
      FIELDS.map(({ id }) => (values[id] = 0));
      if (goal && goal.contribution) {
        FIELDS.map(({ id }) => (values[id] = goal.contribution[id] || 0));
        if (goal.contribution.goal) {
          FIELDS.map(
            ({ id }) => (values.goal[id] = goal.contribution.goal[id] || 0)
          );
        }
      }
      formik.setValues(values);
      setInitialized(true);
    } catch {
      setError(true);
    }
  }, [goal && goal.contribution]);

  const changed = useMemo(() => {
    const order = FIELDS.map(({ id }) => id);
    if (!initialized) return false;
    try {
      if (
        createValueString(order, formik.values.goal) !==
        createValueString(order, goal && goal.contribution.goal)
      ) {
        return true;
      }
      return (
        createValueString(order, formik.values) !==
        createValueString(order, goal && goal.contribution)
      );
    } catch {
      return true;
    }
  }, [goal && goal.contribution, formik.values]);

  useEffect(() => {
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      if (changed) {
        formik.submitForm();
      }
    }, 5000);
  }, [formik.values, changed]);

  // save on exit
  useEffect(
    () => () => {
      formik.submitForm();
    },
    []
  );

  useEffect(() => {
    const total =
      (Number(formik.values.hours) || 0) + (Number(formik.values.dollars) || 0);
    formik.setFieldValue("total", total);
  }, [formik.values.hours, formik.values.dollars]);

  useEffect(() => {
    const total =
      (Number(formik.values.goal.hours) || 0) +
      (Number(formik.values.goal.dollars) || 0);
    formik.setFieldValue("goal.total", total);
  }, [formik.values.goal.hour, formik.values.goal.dollars]);

  const rows = (
    <>
      <EntrySecondaryRow
        goal={goal}
        yearly
        prepend="$"
        section="contribution"
        formik={formik}
        {...FIELDS[0]}
        showBox={isSmallMobile}
      />
      <EntrySecondaryRow
        goal={goal}
        yearly
        prepend="$"
        section="contribution"
        formik={formik}
        {...FIELDS[1]}
        showBox={isSmallMobile}
      />
      <EntrySecondaryRow
        goal={goal}
        prepend="$"
        yearly
        section="contribution"
        formik={formik}
        {...FIELDS[2]}
        showBox={isSmallMobile}
      />
    </>
  );

  return (
    <GoalSectionHolder
      onRefresh={getGoalData}
      changed={changed}
      error={error}
      title="Contribution"
      onSave={formik.submitForm}
    >
      <Box onSubmit={formik.handleSubmit} component={"form"}>
        {isSmallMobile && (
          <Box display="flex" flexDirection="column" gap={2}>
            {rows}
          </Box>
        )}
        {!isSmallMobile && (
          <Table size="small" padding="normal" sx={{ width: "100%" }}>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Last Year</TableCell>
                <TableCell>Current Goal</TableCell>
                <TableCell>Difference</TableCell>
                <TableCell>Average</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{rows}</TableBody>
          </Table>
        )}
        <button type="submit" style={{ display: "none" }}></button>
      </Box>
    </GoalSectionHolder>
  );
};
