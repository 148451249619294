import { useEffect, useMemo, useRef, useState } from "react";
import { useFormik } from "formik";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { useInput } from "contexts";
import { GoalRow } from "./GoalRow";
import { GoalSectionHolder } from "components";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { useIsMobile } from "hooks";
import { createValueString } from "utils";

const GOALS = [
  { id: "personal", title: "Personal" },
  { id: "health", title: "Health" },
  { id: "businessCareer", title: "Business / Career" },
  { id: "intimateRelationship", title: "Intimate Relationship" },
  { id: "otherRelationships", title: "Other Relationships" },
  { id: "contribution", title: "Contribution" },
  { id: "funAndAdventure", title: "Fun And Adventure" },
  { id: "finances", title: "Finances" },
];

export const AnnualGoals = () => {
  const { submitSection, goal, getGoalData } = useInput();
  const timer = useRef<any>();
  const [currentGoal, setCurrentGoal] = useState(GOALS[0].id);
  const [goalNumber, setGoalNumber] = useState(0);
  const [error, setError] = useState(false);
  const [initialized, setInitialized] = useState(false);

  const { isMobile } = useIsMobile();

  const formik = useFormik({
    initialValues: {
      [GOALS[0].id]: ["", "", ""],
      [GOALS[1].id]: ["", "", ""],
      [GOALS[2].id]: ["", "", ""],
      [GOALS[3].id]: ["", "", ""],
      [GOALS[4].id]: ["", "", ""],
      [GOALS[5].id]: ["", "", ""],
      [GOALS[6].id]: ["", "", ""],
      [GOALS[7].id]: ["", "", ""],
    },
    onSubmit: (e: any) => {
      if (!initialized) return;
      if (changed) submitSection("annualGoals", e);
      if (timer.current) {
        clearTimeout(timer.current);
      }
    },
  });

  const changed = useMemo(() => {
    const order = GOALS.map(({ id }) => id);
    if (!initialized) return false;
    try {
      return (
        createValueString(order, formik.values) !==
        createValueString(order, goal && goal.annualGoals)
      );
    } catch {
      return true;
    }
  }, [formik.values, goal && goal.annualGoals]);

  useEffect(() => {
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      if (changed) {
        formik.submitForm();
      }
    }, 5000);
  }, [formik.values, changed]);

  useEffect(() => {
    setGoalNumber(0);
  }, [currentGoal]);

  useEffect(() => {
    setError(false);
    try {
      const { annualGoals } = goal;

      if (annualGoals) {
        formik.setValues(annualGoals);
        setInitialized(true);
      }
    } catch {
      setError(true);
    }
  }, [goal && goal.annualGoals]);

  const currentTitle = useMemo(() => {
    try {
      return String(GOALS.find((goal) => goal.id === currentGoal)?.title);
    } catch {}
    return "";
  }, [currentGoal]);

  useEffect(
    () => () => {
      formik.submitForm();
    },
    []
  );

  return (
    <GoalSectionHolder
      onRefresh={getGoalData}
      error={error}
      changed={changed}
      title="Goals"
      onSave={formik.submitForm}
    >
      <Box
        component={"form"}
        onSubmit={formik.handleSubmit}
        display="flex"
        flexDirection={isMobile ? "column" : "row"}
        gap={isMobile ? 1 : 3}
        width={"100%"}
        height={"100%"}
      >
        {isMobile && (
          <FormControl sx={{ mt: 1 }}>
            <InputLabel size="small">Goal Objective</InputLabel>
            <Select
              label="Goal Objective"
              fullWidth
              size="small"
              value={currentGoal}
              onChange={(e) => setCurrentGoal(e.target.value)}
            >
              {GOALS.map((part, index) => (
                <MenuItem key={"goalselect" + index} value={part.id}>
                  {part.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {!isMobile && (
          <Box
            width={250}
            display="flex"
            flexDirection="column"
            gap={1}
            overflow="auto"
          >
            {GOALS.map((part, index) => (
              <Box
                key={index}
                component={"a"}
                sx={{
                  transition: "all 0.2s",
                  cursor: "pointer",
                  fontWeight: "bold",
                  paddingBottom: 1.5,
                  display: "flex",
                  alignItems: "center",
                  paddingTop: 1,
                  "&:not(:last-child)": {
                    borderBottom: "1px solid",
                    borderColor: "grey.700",
                  },
                  "& > *": {
                    transition: "all 0.2s",
                    color: currentGoal == part.id ? "white" : "grey.500",
                  },
                  "& .bullet": {
                    transition: "all 0.2s",
                    height: currentGoal == part.id ? 10 : 0,
                    width: currentGoal == part.id ? 10 : 0,
                  },
                }}
                onClick={() => setCurrentGoal(part.id)}
              >
                <Box
                  className="bullet"
                  sx={{
                    height: 10,
                    width: 10,
                    backgroundColor: "white",
                    mr: 1,
                    borderRadius: "50%",
                  }}
                ></Box>
                <Typography>{part.title}</Typography>
              </Box>
            ))}
          </Box>
        )}
        <Box
          flex={1}
          display={"flex"}
          flexDirection="column"
          gap={isMobile ? 1 : 3}
          overflow={"auto"}
        >
          <ToggleButtonGroup
            size="small"
            fullWidth={isMobile}
            color="primary"
            value={goalNumber}
            onChange={(e, val) => {
              if (val || val == 0) setGoalNumber(Number(val));
            }}
            exclusive
          >
            <ToggleButton value={0}>Goal #1</ToggleButton>
            <ToggleButton value={1}>Goal #2</ToggleButton>
            <ToggleButton value={2}>Goal #3</ToggleButton>
          </ToggleButtonGroup>
          <GoalRow
            title={currentTitle}
            index={goalNumber}
            formik={formik}
            currentGoal={currentGoal}
          />
        </Box>
      </Box>
    </GoalSectionHolder>
  );
};
