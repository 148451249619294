import { FC } from "react";

import InputAdornment from "@mui/material/InputAdornment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Box, Paper } from "@mui/material";
import { AverageCell, DifferenceCell, LastQuarterCell } from "./SpecialCells";
import { localizeNumber } from "utils";

export const EntryRow: FC<{
  noComparison?: boolean;
  goal: any;
  yearly?: boolean;
  prepend?: string;
  id: string;
  label: string;
  sublabel?: string;
  section: string;
  formik: any;
  showBox?: boolean;
  hideAverage?: boolean;
}> = ({
  goal,
  id,
  label,
  formik,
  prepend,
  showBox,
  section,
  yearly,
  noComparison,
  sublabel,
  hideAverage,
}) => {
  if (showBox)
    return (
      <Paper variant="outlined" sx={{ p: 2 }}>
        <Typography fontWeight="bold">
          {label}
          {sublabel && (
            <Typography display="block" variant="caption">
              {sublabel}
            </Typography>
          )}
        </Typography>
        <Table sx={{ mt: 2 }} padding="none">
          <TableBody>
            <TableRow>
              <TableCell sx={{ py: 2 }}>
                <Typography>Current</Typography>
              </TableCell>
              <TableCell>
                <TextField
                  autoComplete="off"
                  name={id}
                  onChange={(e) => {
                    const value = Number(e.target.value);
                    if (isNaN(Number(value))) return;
                    if (Number(value) < 0) return;
                    formik.setFieldValue(id, value);
                  }}
                  value={(formik.values as any)[id]}
                  fullWidth
                  variant="outlined"
                  size="small"
                  prefix="$"
                  InputProps={{
                    startAdornment: prepend && (
                      <InputAdornment position="start">
                        {prepend}
                      </InputAdornment>
                    ),
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ py: 2 }}>
                <Typography>Last {yearly ? "Year" : "Quarter"}</Typography>
              </TableCell>
              <LastQuarterCell
                goal={goal}
                section={section}
                id={id}
                prepend={prepend}
              />
            </TableRow>
            {!noComparison && (
              <TableRow>
                <TableCell sx={{ py: 2 }}>
                  <Typography>Difference</Typography>
                </TableCell>
                <DifferenceCell
                  goal={goal}
                  section={section}
                  id={id}
                  current={(formik.values as any)[id]}
                  prepend={prepend}
                />
              </TableRow>
            )}
            {!hideAverage && (
              <TableRow>
                <TableCell sx={{ py: 2 }}>
                  <Typography>Average</Typography>
                </TableCell>
                <AverageCell
                  goal={goal}
                  section={section}
                  id={id}
                  prepend={prepend}
                />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Paper>
    );

  return (
    <TableRow key={id}>
      <TableCell>
        {label}
        {sublabel && (
          <Typography display="block" variant="caption">
            {sublabel}
          </Typography>
        )}
      </TableCell>
      <TableCell width={200}>
        <TextField
          name={id}
          onChange={(e) => {
            const value = Number(e.target.value);
            if (isNaN(Number(value))) return;
            if (Number(value) < 0) return;
            formik.setFieldValue(id, value);
          }}
          value={(formik.values as any)[id]}
          fullWidth
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: prepend && (
              <InputAdornment position="start">{prepend}</InputAdornment>
            ),
          }}
        />
      </TableCell>
      <LastQuarterCell
        goal={goal}
        prepend={prepend}
        section={section}
        id={id}
      />
      {noComparison ? (
        <TableCell>-</TableCell>
      ) : (
        <DifferenceCell
          goal={goal}
          prepend={prepend}
          section={section}
          id={id}
          current={(formik.values as any)[id]}
        />
      )}
      {!hideAverage && (
        <AverageCell goal={goal} prepend={prepend} section={section} id={id} />
      )}
    </TableRow>
  );
};

export const EntrySecondaryRow: FC<{
  noComparison?: boolean;
  goal: any;
  yearly?: boolean;
  prepend?: string;
  id: string;
  label: string;
  sublabel?: string;
  section: string;
  formik: any;
  showBox?: boolean;
  hideAverage?: boolean;
  reverseDifference?: boolean;
}> = ({
  goal,
  id,
  label,
  formik,
  prepend,
  showBox,
  section,
  yearly,
  noComparison,
  sublabel,
  hideAverage,
  reverseDifference,
}) => {
  if (showBox) {
    return (
      <Paper variant="outlined" sx={{ p: 2 }}>
        <Typography fontWeight="bold">
          {label}
          {sublabel && (
            <Typography display="block" variant="caption">
              {sublabel}
            </Typography>
          )}
        </Typography>
        <Table sx={{ mt: 2 }} padding="none">
          <TableBody>
            <TableRow>
              <TableCell sx={{ py: 2 }}>
                <Typography>Current</Typography>
              </TableCell>
              <TableCell>
                <TextField
                  name={id}
                  onChange={(e) => {
                    const value = Number(e.target.value);
                    if (isNaN(Number(value))) return;
                    if (Number(value) < 0) return;
                    formik.setFieldValue(id, value);
                  }}
                  value={(() => {
                    try {
                      return (formik.values as any)[id];
                    } catch {
                      return "";
                    }
                  })()}
                  fullWidth
                  variant="outlined"
                  size="small"
                  InputProps={{
                    startAdornment: prepend && (
                      <InputAdornment position="start">
                        {prepend}
                      </InputAdornment>
                    ),
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ py: 2 }}>
                <Typography>Current Goal</Typography>
              </TableCell>
              <TableCell>
                <TextField
                  name={`goal.${id}`}
                  onChange={(e) => {
                    const value = Number(e.target.value);
                    if (isNaN(Number(value))) return;
                    if (Number(value) < 0) return;
                    formik.setFieldValue(`goal.${id}`, value);
                  }}
                  value={(() => {
                    try {
                      return (formik.values as any)["goal"][id];
                    } catch {
                      return "";
                    }
                  })()}
                  fullWidth
                  variant="outlined"
                  size="small"
                  InputProps={{
                    startAdornment: prepend && (
                      <InputAdornment position="start">
                        {prepend}
                      </InputAdornment>
                    ),
                  }}
                />
              </TableCell>
            </TableRow>
            {!noComparison && (
              <TableRow>
                <TableCell sx={{ py: 2 }}>
                  <Typography>Difference</Typography>
                </TableCell>
                <DifferenceCell
                  goal={goal}
                  prepend={prepend}
                  section={section}
                  id={id}
                  reverse={reverseDifference}
                  current={(formik.values as any)[id]}
                  against={(formik.values as any).goal[id]}
                />
              </TableRow>
            )}
            {!hideAverage && (
              <TableRow>
                <TableCell sx={{ py: 2 }}>
                  <Typography>Average</Typography>
                </TableCell>
                <AverageCell
                  goal={goal}
                  section={section}
                  id={id}
                  prepend={prepend}
                />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Paper>
    );
  }

  return (
    <TableRow key={id}>
      <TableCell>
        {label}
        {sublabel && (
          <Typography display="block" variant="caption">
            {sublabel}
          </Typography>
        )}
      </TableCell>
      <TableCell width={200}>
        <TextField
          name={id}
          onChange={(e) => {
            const value = Number(e.target.value);
            if (isNaN(Number(value))) return;
            if (Number(value) < 0) return;
            formik.setFieldValue(id, value);
          }}
          value={(() => {
            try {
              return (formik.values as any)[id];
            } catch {
              return "";
            }
          })()}
          fullWidth
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: prepend && (
              <InputAdornment position="start">{prepend}</InputAdornment>
            ),
          }}
        />
      </TableCell>
      <TableCell width={200}>
        <TextField
          name={`goal.${id}`}
          onChange={(e) => {
            const value = Number(e.target.value);
            if (isNaN(Number(value))) return;
            if (Number(value) < 0) return;
            formik.setFieldValue(`goal.${id}`, value);
          }}
          value={(() => {
            try {
              return (formik.values as any)["goal"][id];
            } catch {
              return "";
            }
          })()}
          fullWidth
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: prepend && (
              <InputAdornment position="start">{prepend}</InputAdornment>
            ),
          }}
        />
      </TableCell>
      {noComparison ? (
        <TableCell>-</TableCell>
      ) : (
        <DifferenceCell
          reverse={reverseDifference}
          goal={goal}
          prepend={prepend}
          section={section}
          id={id}
          current={(formik.values as any)[id]}
          against={(formik.values as any).goal[id]}
        />
      )}
      {!hideAverage && (
        <AverageCell goal={goal} prepend={prepend} section={section} id={id} />
      )}
    </TableRow>
  );
};

export const TotalCalculationRow: FC<{
  isGoal?: boolean;
  label: string;
  values: any;
  yearly?: boolean;
  prepend?: string;
  postpend?: string;
  showBox?: boolean;
  sublabel?: string;
  hideAverage?: boolean;
  reverseDifference?: boolean;
}> = ({
  label,
  prepend = "$",
  postpend = "",
  values,
  showBox,
  yearly,
  sublabel,
  hideAverage,
  isGoal,
  reverseDifference,
}) => {
  let _prepend = prepend ? prepend + " " : "";
  let _postpend = postpend ? " " + postpend : "";

  const { current, last, average } = values;

  const _current = isNaN(current) ? 0 : current;
  const _last = isNaN(last) ? 0 : last;
  const _average = isNaN(average) ? 0 : average;

  const difference = _current - _last;

  if (showBox)
    return (
      <Paper variant="outlined" sx={{ p: 2 }}>
        <Box>
          <Typography fontWeight="bold">{label}</Typography>
          {sublabel && (
            <Typography variant="body2" fontSize={12}>
              {sublabel}
            </Typography>
          )}
        </Box>
        <Table sx={{ mt: 2 }}>
          <TableBody>
            <TableRow>
              <TableCell>
                <Typography>Current</Typography>
              </TableCell>
              <TableCell>
                {_prepend}
                {_current}
                {_postpend}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography>
                  {isGoal
                    ? "Current Goal"
                    : `Last ${yearly ? "Year" : "Quarter"}`}
                </Typography>
              </TableCell>
              <TableCell>
                {_prepend}
                {_last}
                {_postpend}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography>Difference</Typography>
              </TableCell>
              <DifferenceCell
                reverse={reverseDifference}
                goal={false}
                value={difference}
              />
            </TableRow>
            {!hideAverage && (
              <TableRow>
                <TableCell>
                  <Typography>Average</Typography>
                </TableCell>
                <TableCell>
                  {_prepend}
                  {_average}
                  {_postpend}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Paper>
    );

  return (
    <TableRow>
      <TableCell>
        <Box sx={{ py: 1 }}>
          <Typography fontWeight="bold">{label}</Typography>
          {sublabel && (
            <Typography variant="body2" fontSize={12}>
              {sublabel}
            </Typography>
          )}
        </Box>
      </TableCell>
      <TableCell width={200}>
        {_prepend}
        {localizeNumber(_current)}
        {_postpend}
      </TableCell>
      <TableCell>
        {_prepend}
        {localizeNumber(_last)}
        {_postpend}
      </TableCell>
      <DifferenceCell
        reverse={reverseDifference}
        goal={false}
        prepend={prepend}
        value={difference}
      />
      {!hideAverage && (
        <TableCell>
          {_prepend}
          {_average}
          {_postpend}
        </TableCell>
      )}
    </TableRow>
  );
};
