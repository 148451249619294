import Box from "@mui/material/Box";
import { InputProvider } from "contexts";
import { Header } from "./Header";
import { MainBody } from "./MainBody";

export const InputPage = () => {
  return (
    <InputProvider>
      <Box className="input" display="flex" flexDirection="column" flex={1}>
        <Header />
        <MainBody />
      </Box>
    </InputProvider>
  );
};
