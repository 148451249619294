import axios from "axios";

const isProduction = process.env.NODE_ENV !== "production";

const baseURL = isProduction
  ? "http://localhost:3003/api"
  : process.env.REACT_APP_BACKEND_API || "/api";

export const apiCaller = axios.create({
  baseURL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": baseURL,
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Methods": [
      "GET",
      "POST",
      "PUT",
      "PATCH",
      "DELETE",
      "OPTIONS",
    ],
  },
});
