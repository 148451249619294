import { Box, Chip, Typography } from "@mui/material";
import { useApp } from "contexts";

export const UserInfoBox = () => {
  const {
    user,
    user: { isAdmin, isSuperAdmin },
  } = useApp();

  return (
    <Box
      sx={{
        p: 1,
        m: 1,
        overflow: "hidden",
        border: "1px solid",
        borderColor: "grey.800",
        borderRadius: 1,
        backgroundColor: "grey.900",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      }}
    >
      {!isAdmin && (
        <Typography
          variant="h6"
          sx={{
            overflow: "hidden",
            width: "100%",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
          fontWeight="bold"
        >
          {user.display_name}
        </Typography>
      )}
      {isAdmin && (
        <Box>
          Logged in as{" "}
          <Chip label={isSuperAdmin ? "Super Admin" : "Admin"} size="small" />
        </Box>
      )}
      <Typography
        variant="body2"
        sx={{
          overflow: "hidden",
          width: "100%",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {user.email}
      </Typography>
    </Box>
  );
};
