import { useEffect, useMemo, useRef, useState } from "react";
import { useFormik } from "formik";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useInput } from "contexts";
import { GoalSectionHolder } from "components";
import { Button, Paper } from "@mui/material";
import { useIsMobile } from "hooks";
import { ListItem } from "./ListItem";
import { Add } from "@mui/icons-material";

const initialValue = ["", "", ""];

export const BucketList = () => {
  const { submitSection, goal, getGoalData } = useInput();
  const timer = useRef<any>();
  const [error, setError] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const { isMobile } = useIsMobile();

  const formik = useFormik({
    initialValues: {
      bucketList: initialValue,
    },
    onSubmit: (e: any) => {
      if (!initialized) return;
      if (changed) submitSection("bucketList", e.bucketList);
      if (timer.current) {
        clearTimeout(timer.current);
      }
    },
  });

  useEffect(() => {
    setError(false);
    try {
      let bucketList = initialValue;
      if (goal.bucketList) {
        bucketList = goal.bucketList;
      }
      const length = bucketList.length;
      if (length < 3) bucketList.push(...Array(3 - length).fill(""));
      formik.setValues({
        bucketList,
      });
      setInitialized(true);
    } catch {
      setError(true);
    }
  }, [goal && goal.bucketList]);

  const onAddRow = () => {
    try {
      const list = [...formik.values.bucketList, ""];
      formik.setFieldValue("bucketList", list);
    } catch {
      setError(true);
    }
  };

  const changed = useMemo(() => {
    if (!initialized) return false;
    try {
      return (
        JSON.stringify(formik.values.bucketList) !==
        JSON.stringify(goal && goal.bucketList)
      );
    } catch {
      return true;
    }
  }, [formik.values, goal && goal.bucketList]);

  useEffect(() => {
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      if (changed) {
        formik.submitForm();
      }
    }, 5000);
  }, [formik.values, changed]);

  const onDeleteRow = (index: number) => {
    try {
      const list = [...formik.values.bucketList];
      list.splice(index, 1);
      formik.setFieldValue("bucketList", list);
    } catch {
      setError(true);
    }
  };

  const onChange = (index: number, value: string) => {
    formik.setFieldValue(`bucketList[${index}]`, value);
  };

  // save on exit
  useEffect(
    () => () => {
      formik.submitForm();
    },
    []
  );

  return (
    <GoalSectionHolder
      onRefresh={getGoalData}
      error={error}
      changed={changed}
      title="Bucket List"
      onSave={formik.submitForm}
    >
      <Box component={"form"} onSubmit={formik.handleSubmit}>
        <Box paddingBottom={4}>
          <Typography variant="subtitle2">
            What are the Top 20 experiences you most want to have in this
            lifetime?
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection={isMobile ? "column" : "row"}
          gap={isMobile ? 1 : 3}
          width={"100%"}
        >
          <Paper
            variant="outlined"
            sx={{
              width: "100%",
              p: 2,
              "& > *:not(:last-child)": {
                pb: 2,
              },
            }}
          >
            {formik.values.bucketList.map((item: string, index: number) => (
              <ListItem
                key={"bucketlist" + index}
                value={item}
                onChange={(value) => onChange(index, value)}
                onDelete={() => onDeleteRow(index)}
              />
            ))}
            {formik.values.bucketList.length < 20 && (
              <Button onClick={onAddRow} startIcon={<Add />}>
                Add Item
              </Button>
            )}
          </Paper>
        </Box>
        <button style={{ display: "none" }} type="submit"></button>
      </Box>
    </GoalSectionHolder>
  );
};
