import { Box } from "@mui/material";
import { ConditionalView, GoalSectionNavigation } from "components";
import { useInput } from "contexts";
import { FC, useEffect, useState } from "react";
import { FinancialGrowth } from "./sections/FinancialGrowth";
import { LifeVision } from "./sections/LifeVision";
import { Contribution } from "./sections/Contribution";
import { Mindset } from "./sections/Mindset";
import { AnnualRecap } from "./sections/AnnualRecap";
import { Health } from "./sections/Health";
import { TopQuarterlyGoals } from "./sections/TopQuarterlyGoals";
import { LifeHappinessIndex } from "./sections/LifeHappinessIndex";
import { NetWorthCalculator } from "./sections/NetWorthCalculator";
import { BucketList } from "./sections/BucketList";
import { Priorities } from "./sections/Priorities";
import { Mission } from "./sections/Mission";
import { Theme } from "./sections/Theme";
import { AnnualGoals } from "./sections/AnnualGoals";

const QuarterlyView: FC<{ selectedDateType: string; currentPart: number }> = ({
  selectedDateType,
  currentPart,
}) => {
  return (
    <ConditionalView expectedValue="quarterly" currentValue={selectedDateType}>
      {currentPart === 0 && <TopQuarterlyGoals />}
      {currentPart === 1 && <LifeHappinessIndex />}
      {currentPart === 2 && <Health />}
    </ConditionalView>
  );
};

const YearlyView: FC<{ selectedDateType: string; currentPart: number }> = ({
  selectedDateType,
  currentPart,
}) => {
  return (
    <ConditionalView expectedValue="yearly" currentValue={selectedDateType}>
      {currentPart === 0 && <Theme />}
      {currentPart === 1 && <LifeVision />}
      {currentPart === 2 && <AnnualGoals />}
      {currentPart === 3 && <Mindset />}
      {currentPart === 4 && <FinancialGrowth />}
      {currentPart === 5 && <Contribution />}
      {currentPart === 6 && <AnnualRecap />}
      {currentPart === 7 && <NetWorthCalculator />}
      {currentPart === 8 && <BucketList />}
      {currentPart === 9 && <Priorities />}
      {currentPart === 10 && <Mission />}
    </ConditionalView>
  );
};

export const MainBody = () => {
  const { selectedDateType } = useInput();
  const [currentPart, setCurrentPart] = useState(0);

  useEffect(() => {
    setCurrentPart(0);
  }, [selectedDateType]);

  return (
    <Box flex={1} sx={{ overflowY: "auto" }} className="parent">
      <GoalSectionNavigation
        currentPart={currentPart}
        setCurrentPart={(index: any) => {
          setCurrentPart(index);
        }}
        selectedDateType={selectedDateType}
      >
        <QuarterlyView
          selectedDateType={selectedDateType}
          currentPart={currentPart}
        />
        <YearlyView
          selectedDateType={selectedDateType}
          currentPart={currentPart}
        />
      </GoalSectionNavigation>
    </Box>
  );
};
