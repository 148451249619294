import { useFormik } from "formik";
import { useEffect, useMemo, useRef, useState } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { VisionInput } from "./VisionInput";
import { GoalSectionHolder } from "components";
import { useInput } from "contexts";
import { useIsMobile } from "hooks";
import { createValueString } from "utils";

export const LIFEVISION_FIELDS = [
  {
    id: "self",
    title: "How do you feel about yourself personally?",
    subtitle: "Examples are confident, radiant, etc.",
  },
  {
    id: "health",
    title: "How do you want to feel about your health?",
    subtitle: "Examples are ageless, balanced, powerful, strong, etc.",
  },
  {
    id: "business",
    title: "How do you want to feel in business?",
    subtitle:
      "Examples are powerful, in flow, contributing at a high level, alignment, etc.",
  },
  {
    id: "intimateRelationship",
    title: "How do you want to feel in your intimate relationship?",
    subtitle: "Examples are connected, appreciated, safe, etc.",
  },
  {
    id: "otherRelationship",
    title: "How do you want to feel in your other relationships?",
    subtitle: "Examples are connected, authentic, uplifted, supported, etc.",
  },
  {
    id: "contribute",
    title: "How do you want to feel about how you contribute?",
    subtitle: "Examples are Impactful, Influential, etc.",
  },
  {
    id: "adventure",
    title: "How do you want to feel about fun and adventure?",
    subtitle: "Examples include spontaneous, fullfilled, balanced, etc.",
  },
  {
    id: "finances",
    title: "How do you want to feel about your finances?",
    subtitle: "Examples are abundant, magnetic, certain, etc.",
  },
];

export const LifeVision = () => {
  const { submitSection, goal, getGoalData } = useInput();
  const [error, setError] = useState(false);
  const timer = useRef<any>(null);
  const [initialized, setInitialized] = useState(false);

  const { isMobile } = useIsMobile();

  const formik = useFormik({
    initialValues: {},
    onSubmit: (e) => {
      if (!initialized) return;
      if (changed) submitSection("lifeVision", e);
      if (timer.current) {
        clearTimeout(timer.current);
      }
    },
  });

  const changed = useMemo(() => {
    if (!initialized) return false;
    const order = LIFEVISION_FIELDS.map(({ id }) => id);
    try {
      return (
        createValueString(order, formik.values) !==
        createValueString(order, goal && goal.lifeVision)
      );
    } catch {
      return true;
    }
  }, [formik.values, goal && goal.lifeVision]);

  useEffect(() => {
    setError(false);
    if (!goal.lifeVision) return;
    try {
      const values: any = {};
      LIFEVISION_FIELDS.map(({ id }) => (values[id] = ""));
      if (goal && goal.lifeVision) {
        LIFEVISION_FIELDS.map(({ id }) => (values[id] = goal.lifeVision[id]));
      }
      formik.setValues(values);
      setInitialized(true);
    } catch (err) {
      setError(true);
    }
  }, [goal && goal.lifeVision]);

  // save on exit
  useEffect(
    () => () => {
      formik.submitForm();
    },
    []
  );

  useEffect(() => {
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      if (changed) {
        formik.submitForm();
      }
    }, 5000);
  }, [formik.values, changed]);

  return (
    <GoalSectionHolder
      onRefresh={getGoalData}
      error={error}
      title="Life Vision"
      onSave={formik.handleSubmit}
      changed={changed}
    >
      <Box paddingBottom={4}>
        <Typography variant={isMobile ? "h5" : "h4"}>
          We are going to start with a vision for how you want to <b>feel</b>.
        </Typography>
        <Typography variant="subtitle2">
          Please list words or phrases that you want to <b>feel</b> in every
          area of your life!
        </Typography>
      </Box>
      <Box
        onSubmit={formik.handleSubmit}
        component={"form"}
        display="flex"
        gap={3}
        flexDirection="column"
      >
        {LIFEVISION_FIELDS.map((data) => (
          <VisionInput
            key={"lifeVision-" + data.id}
            id={data.id}
            formik={formik}
            title={data.title}
            subtitle={data.subtitle}
          />
        ))}
        <button type="submit" style={{ display: "none" }}></button>
      </Box>
    </GoalSectionHolder>
  );
};
