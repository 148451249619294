import { useEffect, useState, useMemo, useRef } from "react";
import { useFormik } from "formik";
import Box from "@mui/material/Box";

import { GoalSectionHolder } from "components";
import { useInput } from "contexts";
import { useIsMobile } from "hooks";
import { MenuItem, Select, Tab, Tabs } from "@mui/material";
import { Overview } from "./Overview";
import { ItemList } from "./ItemList";
import { Form } from "./Form";
import { createValueString } from "utils";
import { Goals } from "./Goals";

const VIEWS = ["Assets", "Liabilities", "Goals", "Overview"];

export const NetWorthCalculator = () => {
  const [tab, setTab] = useState(0);
  const { goal, submitSection, getGoalData } = useInput();
  const [error, setError] = useState(false);
  const { isSmallMobile } = useIsMobile();
  const [showForm, setShowForm] = useState(false);
  const [selectedType, setSelectedType] = useState<
    "assets" | "liabilities" | null
  >(null);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const timer = useRef<any>();
  const [initialized, setInitialized] = useState(false);

  const formik = useFormik({
    initialValues: {
      assets: [],
      liabilities: [],
      goals: {
        assets: 0,
        liabilities: 0,
      },
    },
    onSubmit: (e) => {
      if (!initialized) return;
      if (changed) submitSection("netWorthCalculator", e);
      if (timer.current) {
        clearTimeout(timer.current);
      }
    },
  });

  const changed = useMemo(() => {
    const order = ["assets", "liabilities"];
    try {
      if (!initialized) return false;
      const goalsChanged =
        createValueString(order, formik.values.goals) !==
        createValueString(
          order,
          goal.netWorthCalculator.goals || { assets: 0, liabilities: 0 }
        );

      if (goalsChanged) return true;

      return (
        createValueString(order, formik.values) !==
        createValueString(order, goal && goal.netWorthCalculator)
      );
    } catch {
      return true;
    }
  }, [formik.values, goal && goal.netWorthCalculator]);

  useEffect(() => {
    setError(false);
    try {
      const netWorthCalculator = goal && goal.netWorthCalculator;
      if (!netWorthCalculator) return;
      const data = {
        assets: netWorthCalculator.assets || [],
        liabilities: netWorthCalculator.liabilities || [],
        goals: netWorthCalculator.goals || { liabilities: 0, assets: 0 },
      };
      formik.setValues(data);
      setInitialized(true);
    } catch {
      setError(true);
    }
  }, [goal && goal.netWorthCalculator]);

  useEffect(() => {
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      if (changed) {
        formik.submitForm();
      }
    }, 5000);
  }, [formik.values, changed]);

  // save on exit
  useEffect(
    () => () => {
      formik.submitForm();
    },
    []
  );

  const onAdd = (type: "assets" | "liabilities") => {
    setSelectedType(type);
    setShowForm(true);
    setSelectedItem(null);
  };

  const onEdit = (type: "assets" | "liabilities", index: number) => {
    onAdd(type);
    const item: any = formik.values[type][index];
    if (item) {
      setSelectedItem({ ...item, index });
    }
  };

  const onDelete = (type: "assets" | "liabilities", index: number) => {
    const list = formik.values[type];
    const newList = [...list];
    newList.splice(index, 1);
    formik.setFieldValue(type, newList);
  };

  const onSubmit = (values: any) => {
    if (!selectedType) return;
    const list = formik.values[selectedType];
    if (!selectedItem) {
      const newList = [...list, values];
      formik.setFieldValue(selectedType, newList);
    } else {
      const newList = [...list];
      const { index, name, value } = values;
      (newList as any)[index] = { name, value };
      formik.setFieldValue(selectedType, newList);
    }
    setShowForm(false);
  };

  const [assetsValue, liabilitiesValue] = useMemo(() => {
    try {
      const { assets, liabilities } = formik.values;

      const assetsValue = assets.reduce(
        (prev, curr: any) => prev + Number(curr.value),
        0
      );
      const liabilitiesValue = liabilities.reduce(
        (prev, curr: any) => prev + Number(curr.value),
        0
      );

      return [assetsValue, liabilitiesValue];
    } catch {}

    return [0, 0];
  }, [formik.values]);

  return (
    <>
      <Form
        selectedItem={selectedItem}
        type={selectedType}
        onSubmit={onSubmit}
        open={showForm}
        onClose={() => setShowForm(false)}
      />
      <GoalSectionHolder
        onRefresh={getGoalData}
        error={error}
        changed={changed}
        title="Net Worth Calculator"
        onSave={formik.submitForm}
      >
        {!isSmallMobile && (
          <Tabs
            scrollButtons="auto"
            value={tab}
            onChange={(e, newTab) => setTab(newTab)}
          >
            {VIEWS.map((view) => (
              <Tab label={view} key={"tab" + view} />
            ))}
          </Tabs>
        )}
        {isSmallMobile && (
          <Select
            fullWidth
            size="small"
            value={tab}
            onChange={(e) => setTab(Number(e.target.value))}
          >
            {VIEWS.map((view, index) => (
              <MenuItem value={index} key={"select" + view}>
                {view}
              </MenuItem>
            ))}
          </Select>
        )}
        <Box component={"form"} onSubmit={formik.handleSubmit}>
          {tab == 0 && (
            <ItemList
              title="List of Assets"
              onAdd={() => onAdd("assets")}
              list={formik.values.assets}
              onDelete={(index) => onDelete("assets", index)}
              onEdit={(index) => onEdit("assets", index)}
            />
          )}
          {tab == 1 && (
            <ItemList
              title="List of Liabilities"
              onAdd={() => onAdd("liabilities")}
              list={formik.values.liabilities}
              onDelete={(index) => onDelete("liabilities", index)}
              onEdit={(index) => onEdit("liabilities", index)}
            />
          )}
          {tab == 2 && <Goals formik={formik} />}
          {tab == 3 && (
            <Overview assets={assetsValue} liabilities={liabilitiesValue} />
          )}
          <button style={{ display: "none" }} type="submit"></button>
        </Box>
      </GoalSectionHolder>
    </>
  );
};
